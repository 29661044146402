<template>
  <div class="Office">
    <Row>
      <div class="Office-body">
        <div class="titles">
          {{ office.name }}
          <span class="secondary">
            {{ office.district }}
          </span>
        </div>
        <p v-if="office.description" class="description">
          {{ office.description }}
        </p>
        <div class="line">
          <strong>T:</strong><Phone :number="office.phone" />
        </div>
        <div class="line">
          <strong>{{ $t('email') }}:</strong>
          <Email :address="office.email" />
        </div>
        <div v-if="office.address" class="line">
          <strong>{{ $t('address') }}:</strong><span>{{ office.address }}</span>
        </div>
      </div>
    </Row>
  </div>
</template>

<script>
export default {
  props: {
    office: Object,
  },
}
</script>

<style lang="scss">
.Office {
  margin: 5rem 0;
  @include md {
    margin: 8rem 0;
  }

  > .Row {
    display: flex;
    justify-content: flex-start;
  }

  .titles {
    font-size: $h35;
    margin-bottom: $h35;

    > .secondary {
      font-size: $h8;
      margin-left: 1.1rem;
      opacity: 0.5;
    }
  }

  .description {
    font-size: $h7;
    margin-bottom: 3.2rem;
    opacity: 0.5;
  }

  .line {
    line-height: 1.6rem;
    margin-bottom: 0.625rem;

    > strong {
      color: $blue;
      font-weight: $semibold;
      margin-right: 1.5rem;
    }
  }

  &-body {
    margin: auto;
    max-width: 25rem;
    padding: 0 1rem;
    @include md {
      margin: 0;
    }
  }
}
</style>

<i18n>
{
  "de": {
    "address": "A",
    "email": "E"
  },
  "en": {
    "address": "A",
    "email": "E"
  },
  "it": {
    "address": "A",
    "email": "E"
  },
  "lt": {
    "address": "A",
    "email": "E"
  },
  "pl": {
    "address": "A",
    "email": "E"
  }
}
</i18n>
