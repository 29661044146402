<template>
  <SectionAccordion id="SectionOpenPositions">
    <Wrap>
      <SectionTitle :title="$t('open')">
        <Field
          class="countries"
          :options="[
            [null, $t('allCountries')],
            ...countries,
          ]"
          type="select"
          :value.sync="activeCountry" />
      </SectionTitle>
      <div id="open-positions">
        <Empty v-if="!jobs.length">
          {{ $t('none') }}
        </Empty>

        <OpenPosition
          v-for="job in jobs"
          v-else
          :key="job.id"
          :job="job" />
      </div>
    </Wrap>
  </SectionAccordion>
</template>

<script>
import { getters, uniqueArray } from 'views/utils'
import OpenPosition from './OpenPosition'
import SectionAccordion from 'views/components/SectionAccordion'
import SectionTitle from 'views/components/SectionTitle'

export default {
  components: { OpenPosition, SectionAccordion, SectionTitle },
  data() {
    return {
      activeCountry: null,

      // activeTagIndex: 0,
      // tags: [
      //   {
      //     label: 'All',
      //     doIgnore: true,
      //   },
      //   {
      //     label: 'Energy',
      //   },
      //   {
      //     label: 'Solar',
      //   },
      // ],
    }
  },
  computed: {
    // activeTag() {
    //   return this.tags[this.activeTagIndex]
    // },
    jobs() {
      const query = {
        // locale: this.locale,
        // ...(this.activeTag ? { tag: this.activeTag } : {}),
        ...(this.activeCountry ? { country: this.activeCountry } : {}),
      }

      return this.query('career', query)
    },
    countries() {
      return uniqueArray(
        this.query('career', { locale: this.locale }).map(career => career.country),
      ).map(country => [country, country])
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
#SectionOpenPositions {
  #open-positions {
    background-color: $white;
    padding: 3rem 2rem 0;
    @include md {
      padding: 6rem 6rem 3rem;
    }
    @include lg {
      padding: 12rem 12rem 9rem;
    }
  }
}
</style>

<i18n>
{
  "de": {
    "allCountries": "Alle Länder",
    "none": "Keine Jobs gefunden",
    "open": "Offene Stellen"
  },
  "en": {
    "allCountries": "All Countries",
    "none": "No jobs found",
    "open": "Open positions"
  },
  "it": {
    "allCountries": "",
    "none": "",
    "open": ""
  },
  "lt": {
    "allCountries": "Visos šalys",
    "none": "Nerasta jokių darbo pasiūlymų",
    "open": "Darbo pasiūlymai"
  },
  "pl": {
    "allCountries": "Wszystkie kraje",
    "none": "Nie znaleziono żadnych ofert pracy",
    "open": "Bieżące rekrutacje"
  }
}
</i18n>
