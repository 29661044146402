<template>
  <section id="SectionTeamAll">
    <Wrap class="lg">
      <SectionTitle :title="$t('title')">
        <Field
          class="countries"
          :options="[
            [null, $t('allCountries')],
            ...countries,
          ]"
          type="select"
          :value.sync="activeCountry" />
      </SectionTitle>
      <Row v-for="(membersRow, index) in membersRows" :key="index">
        <Column
          v-for="member in membersRow"
          :key="member.id"
          class="member"
          :md="6"
          :xl="4">
          <TeamMemberSmall :member="member" />
        </Column>
      </Row>
    </Wrap>
  </section>
</template>

<script>
import { getters, uniqueArray } from 'views/utils'
import SectionTitle from 'views/components/SectionTitle'
import TeamMemberSmall from './TeamMemberSmall'

export default {
  components: { SectionTitle, TeamMemberSmall },
  data() {
    return {
      activeCountry: null,
    }
  },
  computed: {
    countries() {
      return uniqueArray(this.members
        .map(career => career.country))
        .map(country => [country, country])
    },
    members() {
      return this.query('member', {
        is_featured: {
          $ne: true,
        },
        locale: this.locale,
        $sort: { priority: 1 },
      })
    },
    membersLocal() {
      if (!this.activeCountry) return this.members

      return this.members
        .filter(member => member.country === this.activeCountry)
    },
    membersRows() {
      return this.splitIntoRows(this.membersLocal, {
        xl: 3,
        md: 2,
        xs: 1,
      })
    },
    ...getters('splitIntoRows'),
  },
}
</script>

<style lang="scss">
#SectionTeamAll {
  color: $black-true;

  .member {
    margin-bottom: 2rem;
  }
}
</style>

<i18n>
{
  "de": {
    "allCountries": "Alle Länder",
    "title": "Management Team"
  },
  "en": {
    "allCountries": "All countries",
    "title": "Management team"
  },
  "it": {
    "allCountries": "",
    "title": ""
  },
  "lt": {
    "allCountries": "Visos šalys",
    "title": "Vadovybė"
  },
  "pl": {
    "allCountries": "Wszystkie kraje",
    "title": "Nasz zespół"
  }
}
</i18n>
