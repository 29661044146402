<template>
  <section id="SectionQa">
    <h2 class="title">
      {{ $t('title') }}
    </h2>
    <WrapScroll>
      <Column v-for="({ number, q }, index) in faq" :key="index" :lg="4">
        <CardQa :number="number" :question="q" />
      </Column>
      <template #button>
        <Route class="Btn sm inv" :to="{ name: 'FaqLandowners', hash: '#sectionFaq' }">
          {{ $t('all') }}
        </Route>
      </template>
    </WrapScroll>
  </section>
</template>

<script>
import CardQa from './CardQa'
import WrapScroll from 'views/components/WrapScroll'
import faqSections from '../../FaqLandowners/faqSections'

export default {
  components: { CardQa, WrapScroll },
  i18n: { sharedMessages: faqSections },
  computed: {
    faq() {
      return this.$ta('faqSections').flatMap(section => section.faq)
    },
  },
}
</script>

<i18n>
{
  "de": {
    "all": "Mehr sehen",
    "title": "Häufig gestellte Fragen"
  },
  "en": {
    "all": "See more",
    "title": "Frequently Asked Questions"
  },
  "it": {
    "all": "",
    "title": ""
  },
  "lt": {
    "all": "Daugiau",
    "title": "D.U.K"
  },
  "pl": {
    "all": "Pozostałe pytania",
    "title": "Najczęściej zadawane pytania"
  }
}
</i18n>
