<template>
  <section id="SectionTeam">
    <Wrap class="lg">
      <Headline>
        {{ $t('headline') }}
      </Headline>
      <div class="heading">
        <h2>
          {{ $t('title') }}
        </h2>
        <p v-for="(paragraph, index) in $ta('description')" :key="index">
          {{ injectSnippets(paragraph) }}
        </p>
      </div>
      <TeamMember
        v-for="member in featuredMembers"
        :key="member.id"
        :member="member" />
    </Wrap>
  </section>
</template>

<script>
import TeamMember from './TeamMember'
import { getters } from 'views/utils'

export default {
  components: { TeamMember },
  computed: {
    featuredMembers() {
      return this.query('member', {
        is_featured: true,
        locale: this.locale,
      })
    },
    ...getters('injectSnippets'),
  },
}
</script>

<style lang="scss">
#SectionTeam {
  color: $black-true;

  .heading {
    h2 {
      margin-bottom: 4rem;
    }

    p {
      margin-bottom: 1.5rem;
    }
  }
}
</style>

<i18n>
{
  "de": {
    "description": [
      "Das 2017 gegründete SIG-Team vereint derzeit über 190 interne internationale Experten mit einer breiten und bewährten Erfahrung in verschiedenen Bereichen des Solarenergiegeschäfts, insbesondere in den Bereichen Projektentwicklung, Finanzstrukturierung, Recht und EPCm. Das Team kann auf eine Erfolgsbilanz von 184 MW PV-Projekten bei Entwicklung, Bau und Inbetriebnahme verweisen"
    ],
    "headline": "Management",
    "title": "Dynamisch, ehrgeizig, erfahren"
  },
  "en": {
    "description": [
      "Established in 2017, SIG team currently unites over a 100 of in-house international experts with a broad and proven experience in diverse areas of the solar energy business, particularly in the project development, financial structuring, legal, and EPCm. The team holds a track record of more than 200 MW PV projects development, construction and commissioning."
    ],
    "headline": "Management",
    "title": "Dynamic, ambitious, experienced"
  },
  "it": {
    "description": [
      ""
    ],
    "headline": "",
    "title": ""
  },
  "lt": {
    "description": [
      "Įkurta 2017 metais, šiuo metu SIG jungia daugiau nei {experts} patyrusių saulės energetikos projektų valdymo, strūkturinio finansavimo, teisės, statybos ir turto valdymo ekspertų žinias. SIG komanda jau įgyvendino {constructedMw} MW galios saulės energetikos projektus."
    ],
    "headline": "Vadovybė",
    "title": "Dinamiški, ambicingi ir patyrę"
  },
  "pl": {
    "description": [
      "SIG, założony w 2017, posiada aktualnie zespół złożony z ponad {experts} międzynarodowych ekspertów. Mogą oni pochwalić się szerokim, udokumentowanym doświadczeniem w różnych obszarach działalności w zakresie energii słonecznej, w szczególności w opracowywaniu projektów, strukturyzacji finansowej, prawie i EPCm. Zespół posiada doświadczenie w rozwoju, budowie i uruchamianiu projektów PV o mocy {constructedMw} MW."
    ],
    "headline": "Kadra zarządzająca",
    "title": "Dynamiczni, ambitni, doświadczeni"
  }
}
</i18n>
