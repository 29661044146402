<template>
  <div v-if="solution" id="Solution">
    <Hero class="solution" :src="solution && solution.imageHero.url">
      <Row>
        <Column :lg="6">
          <h2>{{ $t('solutions') }} {{ solution.name.toLowerCase() }}</h2>
        </Column>
      </Row>
      <HeroScrollDown hash="#Solution-list" slot="outside" />
    </Hero>
    <div id="Solution-list">
      <Wrap>
        <Row>
          <Column :xl="8">
            <Route v-if="isPolishLandowner || isLithuanianLandowner" :to="{ name: 'LandingLandowners', hash: '#form' }">
              <h2>
                {{ solution.title }}
              </h2>
            </Route>
            <h2 v-else>
              {{ solution.title }}
            </h2>
          </Column>
        </Row>
        <div class="Solution-list-container">
          <SectionSolution
            v-for="section in sections"
            :key="section.id"
            :icon="section.icon"
            :name="section.title">
            <Markdown :markdown="section.content" />
          </SectionSolution>
        </div>
        <div>
          <Wrap>
            <LinksLandowner v-if="isEnglishLandowner" />
            <SectionQA v-if="isPolishLandowner" />
            <SectionBusinessModel v-else-if="isBusinessSolution" />
          </Wrap>
        </div>
      </Wrap>
    </div>
  </div>
</template>

<script>
import { actions, getters } from 'views/utils'
import Hero from 'views/components/Hero'
import HeroScrollDown from 'views/components/HeroScrollDown'
import LinksLandowner from './components/LinksLandowner'
import Meta from 'views/mixins/Meta'
import SectionBusinessModel from 'views/components/SectionBusinessModel'
import SectionQA from './components/SectionQa'
import SectionSolution from './components/SectionSolution'

const Markdown = () => import(/* webpackChunkName: 'showdown' */ 'views/components/Markdown')

export default {
  mixins: [
    Meta(
      function() {
        return `${this.$t('solutions')} - ${this?.solution?.name}`
      },

      ({ solution, sections }) =>
        `${solution?.title || ''} - ${sections?.[0]?.content}`,

      ({ solution }) => {
        try {
          return require(`@/assets/social/${solution.slug}.jpg`)
        } catch (error) {
          return solution?.imageHero?.url
        }
      },
    ),
  ],
  components: {
    Hero,
    HeroScrollDown,
    Markdown,
    SectionBusinessModel,
    SectionQA,
    SectionSolution,
    LinksLandowner,
  },
  computed: {
    isBusiness() {
      return this.isBusinessSolution(this)
    },
    isEnglishLandowner() {
      return this.isLandownerSolution(this) && this.locale === 'en'
    },
    isPolishLandowner() {
      return this.isLandownerSolution(this) && this.locale === 'pl'
    },
    isLithuanianLandowner() {
      return this.isLandownerSolution(this) && this.locale === 'lt'
    },
    sections() {
      return this.solution?.sections
    },
    solution() {
      return this.query('solution', {
        slug: this.$route.params.slug,
      }, true)
    },
    ...getters('isBusinessSolution', 'isLandownerSolution'),
  },
  methods: actions('FETCH_SOLUTION'),
  async beforeMount() {
    await this.LOAD(this.FETCH_SOLUTION())
  },
  mounted() {
    if (this.isLandownerSolution(this)) {
      this.$analytics.fbq.event('ViewContent')
    }
  },
}
</script>

<style lang="scss">
#Solution {
  .Solution-list-container {
    background: $white;
    padding: 2rem 2rem 1rem;
    @include md {
      padding: 3rem;
    }
    @include lg {
      padding: 6rem;
    }
  }

  #SectionBusinessModel {
    background: none;
  }
}

#Solution-list {
  h2 {
    margin: 4rem 0 2rem;
    @include md {
      margin: 8rem 0 4rem;
    }
  }
}
</style>

<i18n>
{
  "de": {
    "solutions": "Lösungen"
  },
  "en": {
    "solutions": "Solutions"
  },
  "it": {
    "solutions": ""
  },
  "lt": {
    "solutions": "Sprendimai"
  },
  "pl": {
    "solutions": "Rozwiązania"
  }
}
</i18n>
