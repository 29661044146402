<template>
  <Card class="CardBlogSmall">
    <Route class="image" :params="{ slug: blog.slug }" tag="div" to="BlogItem">
      <Pic
        :alt="blog.title"
        draggable="false"
        :src="getPostImagePreview(blog)" />
    </Route>
    <div class="body">
      <div class="meta">
        <div class="date">
          {{ $d(new Date(blog.created_at), 'short') }}
        </div>
      </div>
      <Route class="title" :params="{ slug: blog.slug }" to="BlogItem">
        {{ blog.title }}
      </Route>
      <div class="preview">
        {{ blog.previewText }}
      </div>
      <BtnShare class="sm" :to="{ name: 'BlogItem', params: { slug: blog.slug } }" />
    </div>
  </Card>
</template>

<script>
import BtnShare from 'views/components/BtnShare'
import { getters } from '../utils'

export default {
  components: { BtnShare },
  props: {
    blog: Object,
  },
  computed: getters('getPostImagePreview'),
}
</script>

<style lang="scss">
.CardBlogSmall {
  > .image {
    cursor: pointer;
    height: 15rem;
    overflow: hidden;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  > .body {
    height: 25rem;
    padding: 2rem;
    position: relative;

    .BtnShare {
      bottom: 1rem;
      position: absolute;
      right: 1rem;
    }

    .more {
      bottom: 2rem;
      position: absolute;
      right: 2rem;
    }

    > .meta {
      color: $text-color;

      > .date {
        color: $grey;
        display: inline-block;
        vertical-align: middle;
      }
    }

    > a.title {
      @include ellipsis(4);
      color: $text-color;
      font-size: $h4;
      line-height: 2rem;
      margin: 1.25rem 0 1rem;
      min-height: 6rem;
      @include lg {
        min-height: 3rem;
      }
    }

    .preview {
      margin-top: 1rem;
    }
  }
}
</style>
