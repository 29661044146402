<template>
  <div id="LandingFinance">
    <Hero>
      <h1>
        {{ $t('title') }}
      </h1>
    </Hero>
    <PublicBondIssue />
    <InvestmentDetails />
    <Presentation />
    <HowToInvest />
    <GeneralDocuments />
    <SectionOurReach />
  </div>
</template>

<script>
import GeneralDocuments from './GeneralDocuments'
import Hero from 'views/components/Hero'
import HowToInvest from './HowToInvest'
import InvestmentDetails from './InvestmentDetails'
import Meta from 'views/mixins/Meta'
import Presentation from './Presentation'
import PublicBondIssue from './PublicBondIssue'
import SectionOurReach from 'views/components/SectionOurReach'
import { getters } from '../../utils'

export default {
  mixins: [
    Meta(require('@/assets/social/news.jpg')),
  ],
  components: {
    Hero,
    PublicBondIssue,
    SectionOurReach,
    HowToInvest,
    InvestmentDetails,
    Presentation,
    GeneralDocuments,
  },
  computed: {
    ...getters(),
  },
}
</script>

<style lang="scss">
#LandingFinance {
  > .Hero {
    background-image: url('../../../assets/ellipses.png');
    background-position: right;
    background-size: cover;
    min-height: 70vh;
    text-align: center;

    h1 {
      margin-bottom: 4rem;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "meta": {
      "title": "Public bond issue 2024"
    },
    "title": "Public bond issue"
  },
  "lt": {
    "meta": {
      "title": "Viešas obligacijų platinimas 2024"
    },
    "title": "Viešas obligacijų platinimas"
  }
}
</i18n>
