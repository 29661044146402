<template>
  <SectionSplit class="PublicBondIssue2023 reverse" src="../../assets/landing-finances/2.jpg">
    <h3>
      {{ $t('title') }}
    </h3>
    <p v-for="(paragraph, index) in $t('paragraphs')" :key="index">
      {{ paragraph }}
    </p>
  </SectionSplit>
</template>

<script>
import SectionSplit from 'views/components/SectionSplit'

export default {
  components: { SectionSplit },
}
</script>

<style lang="scss">
.PublicBondIssue2023 {
  .body {
    .Wrap > h3 {
      color: $danger;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "title": "Distribution is closed",
    "paragraphs": [
      "Sun Investment Group (SIG), an international solar energy project development group of companies, is starting a public bond issue. With the help of this financing instrument, it is planned to attract 5 million euro investments. The distribution phase starts on October 30th and will continue until November 24th.",
      "Sun Investment Group started its activities in 2017 and currently unites more than 100 professionals in Lithuania, Poland, Italy, and Spain. SIG has a portfolio of more than 2.7 GW, of which almost 300 MW are ready to build project and has a track record of more than 200 MW of already developed, constructed and sold operating projects in Lithuania, Poland and UK."
    ]
  },
  "lt": {
    "title": "Platinimas baigtas",
    "paragraphs": [
      "Tarptautinė saulės energetikos projektų vystymo įmonių grupė „Sun Investment Group“ (SIG) pradeda platinti viešą obligacijų emisiją. Pasitelkiant šią finansavimo priemonę planuojama pritraukti 5 mln. eurų investicijų. Platinimo etapas pradedamas spalio 30 dieną ir tęsis iki lapkričio 24 dienos.",
      "Sun Investment Group (SIG) yra patyrusi atsinaujinčių energijos išteklių projektų vystytymo įmonė. Savo veiklą pradėjusi 2017 metais, šiuo metu vienija daugiau nei 100 profesionalų Lietuvoje, Lenkijoje, Italijoje bei Ispanijoje. SIG portfelį sudaro daugiau nei 2,7 GW iš kurių beveik 300 MW galios sudaro statybai paruošti (RTB) projektai. Iki šiol SIG sėkmingai išvystė, pastatė ir pardavė daugiau nei 200 MW saulės elektrinių Lietuvoje, Lenkijoje ir JK."
    ]
  }
}
</i18n>
