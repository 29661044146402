<template>
  <section class="LinksLandowner">
    <Wrap class="lg">
      <h3>
        {{ $t('title') }}
      </h3>
      <div v-if="links" class="links">
        <Route
          v-for="(link, index) in links"
          :key="index"
          class="Btn"
          :params="link.params"
          :title="link.label"
          :to="link.to">
          {{ link.label }}
        </Route>
      </div>
    </Wrap>
  </section>
</template>

<script>
import { mergeLabels } from 'views/utils'

export default {
  // components: { ProgressionSteps, Tab, Tabs },
  // data() {
  //   return {
  //     routes: [
  //       {
  //         to: {
  //           name: 'Solution',
  //         },
  //       },
  //     ],
  //   }
  // },
  computed: {
    links() {
      const routeName = 'LandingLandowners'
      const locales = [
        'lt',
        'pl',
        'it',
      ]

      const routes = locales.map(locale => ({
        to: routeName,
        params: {
          locale,
        },
      }))

      return mergeLabels(routes, this
        .$ta('linkLabels'))
    },
  },
}
</script>

<style lang="scss">
.LinksLandowner {
  h3 {
    margin-bottom: 3rem;
    text-align: center;
    @include md {
      text-align: left;
    }
  }

  .links {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: space-evenly;
    @include md {
      flex-direction: row;
      gap: unset;

      > .Btn {
        min-width: 20%;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "title": "More information about the solutions for landowners",
    "linkLabels": [
      "In Lithuania",
      "In Poland",
      "In Italy"
    ]
  }
}
</i18n>
