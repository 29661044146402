<template>
  <div id="Contact">
    <Hero class="contact">
      <Wrap>
        <h1>
          {{ $t('title') }}
        </h1>
      </Wrap>
    </Hero>
    <section>
      <Wrap>
        <div id="form">
          <h4>
            {{ $t('fill') }}
          </h4>
          <FormContact :contact="contact" :hasName="false" />
        </div>
      </Wrap>
    </section>
    <section id="offices">
      <Wrap>
        <Row>
          <Column :md="6">
            <h2>{{ $t('offices') }}</h2>
          </Column>
          <Column :md="6">
            <span class="filters">
              <Field
                class="countries"
                :options="countryOptions"
                type="select"
                :value.sync="activeCountry" />
            </span>
          </Column>
        </Row>
        <Row v-for="(officesColumn, rowIndex) in officesRows" :key="rowIndex" class="office-row">
          <Column v-for="office in officesColumn" :key="office.id" class="office-column" :md="6">
            <Office :office="office" />
          </Column>
        </Row>
      </Wrap>
    </section>
  </div>
</template>

<script>
import { actions, getters, uniqueArray } from 'views/utils'
import FormContact from 'views/components/FormContact'
import Hero from 'views/components/Hero'
import Meta from 'views/mixins/Meta'
import Office from './components/Office'

export default {
  mixins: [
    Meta(require('@/assets/social/contact.jpg')),
  ],
  components: { FormContact, Hero, Office },
  data() {
    return {
      contact: this.$store.getters.contactFactory(),
      activeCountry: null,

      // activeTypeIndex: 0,
      // officeTypesValues: [
      //   {
      //     label: 'All',
      //     doIgnore: true,
      //   },
      //   {
      //     label: 'Energy',
      //   },
      //   {
      //     label: 'Solar',
      //   },
      // ],
    }
  },
  computed: {
    countryOptions() {
      return [
        [null, this.$t('allCountries')],
        ...this.countries.map(country => [country, this.$t(country)]),
      ]
    },
    countries() {
      return uniqueArray(this.allOffices
        .map(office => office.country))
      // .map(country => ({
      //   country,
      //   translated: this.$t(country),
      // }))
    },
    // activeType() {
    //   return this.officeTypes[this.activeTypeIndex]
    // },
    // officeTypes() {
    //   return mergeLabels(this.officeTypesValues, this.$ta('types'))
    // },
    allOffices() {
      return this.query('office', {
        locale: this.locale,
      })
    },
    offices() {
      const query = {
        ...this.activeCountry ? { country: this.activeCountry } : {},
        locale: this.locale,
      }

      // if (!this.activeType.doIgnore) {
      //   query.industry = this.activeType.label
      // }

      return this.query('office', query)
    },
    officesRows() {
      return this.splitIntoRows(this.offices, {
        md: 2,
        xs: 1,
      })
    },
    ...getters('splitIntoRows'),
  },
  methods: actions('FETCH_OFFICE'),
  watch: {
    locale: {
      immediate: true,
      handler() {
        const countriesByLocale = {
          lt: 'Lithuania',
          pl: 'Poland',
        }

        this.activeCountry = countriesByLocale[this.locale] || null
      },
    },
  },
  created() {
    this.LOAD(this.FETCH_OFFICE())
  },
}
</script>

<style lang="scss">
$_border-color: rgba($border-color, 0.3);

.Hero.contact {
  background-color: $main;
  background-image: url('../../../assets/ellipses.png');
  background-position: top right;
  background-repeat: no-repeat;
  color: $white;
  min-height: 60vh;
  text-align: center;
  @include lg {
    min-height: 80vh;
  }
}

#Contact {
  #form {
    background: $white;
    padding: 3rem;
    position: relative;
    transform: translateY(-4rem);
    z-index: 1;
    @include md {
      padding: 4rem;
    }
    @include lg {
      transform: translateY(-9rem);
    }

    h4 {
      font-size: $h35;
      margin-bottom: 4rem;
      text-align: center;
    }

    > #FormContact {
      input,
      textarea {
        color: $text-color;
      }
    }
  }
}

#offices {
  padding-top: 0;
  text-align: center;
  @include md {
    text-align: left;
  }

  > h2 {
    margin-bottom: 3rem;
  }

  .filters {
    display: block;
    margin-top: 2rem;
    padding-top: 1rem;
    @include md {
      margin-top: 0;
      text-align: right;
    }

    > .Btn {
      line-height: normal;
      margin-right: 1rem;
      vertical-align: middle;

      &.active {
        background: $white-ter;
        border-color: $white-ter;
      }
    }
  }

  .office-row {
    border-bottom: 1px solid $_border-color;
    position: relative;
    @include md {
      &::before {
        background: linear-gradient($_border-color, $_border-color) no-repeat center/1px 100%;
        bottom: 0;
        content: ' ';
        display: block;
        height: 100%;
        pointer-events: none;
        position: absolute;
        text-align: left;
        width: 100%;
      }

      &:nth-of-type(2)::before {
        bottom: 0;
        height: 100%;
        top: 8rem;
      }

      &:last-of-type {
        &::before {
          bottom: 8rem;
          height: auto;
          top: 0;
        }

        &:nth-of-type(2) {
          &::before {
            bottom: 8rem;
            height: auto;
            top: 8rem;
          }
        }
      }
    }

    .office-column {
      padding: 0 1rem;
      @include md {
        &:nth-of-type(even) .Row {
          justify-content: flex-end;
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }

  > .Wrap {
    padding: 0 2rem;
  }
}
</style>

<i18n>
{
  "de": {
    "Germany": "Deutschland",
    "Italy": "Italien",
    "Lithuania": "Litauen",
    "Poland": "Polen",
    "allCountries": "Alle Länder",
    "fill": "Füllen Sie das Schnellformular aus, um mit uns in Kontakt zu treten",
    "meta": {
      "description": "Kontaktieren Sie uns. Wir sind immer für Sie da, wenn Sie Fragen haben - nehmen Sie Kontakt mit uns auf.",
      "title": "Kontakt"
    },
    "offices": "Unsere Büros",
    "title": "Kontaktieren Sie uns"
  },
  "en": {
    "Germany": "Germany",
    "Italy": "Italy",
    "Lithuania": "Lithuania",
    "Poland": "Poland",
    "allCountries": "All countries",
    "fill": "Fill in the quick form to get in touch",
    "meta": {
      "description": "Contact Us. We are always on hand to assist with any enquiries - get in touch.",
      "title": "Contact"
    },
    "offices": "Our offices",
    "title": "Contact Us"
  },
  "it": {
    "Germany": "Germany",
    "Italy": "Italy",
    "Lithuania": "Lithuania",
    "Poland": "Poland",
    "allCountries": "",
    "fill": "",
    "meta": {
      "description": "",
      "title": ""
    },
    "offices": "",
    "title": ""
  },
  "lt": {
    "Germany": "Vokietija",
    "Italy": "Italija",
    "Lithuania": "Lietuva",
    "Poland": "Lenkija",
    "allCountries": "Visos šalys",
    "fill": "Susisiekite užpildydami formą",
    "meta": {
      "description": "Susisiekite su mumis. Mes visada pasiruošę patarti visais jums rūpimais klausimais. ",
      "title": "Susisiekti"
    },
    "offices": "Mūsų biurai",
    "title": "Susisiekti"
  },
  "pl": {
    "Germany": "Niemcy",
    "Italy": "Włochy",
    "Lithuania": "Litwa",
    "Poland": "Polska",
    "allCountries": "Wszystkie kraje",
    "fill": "Wypełnij krótki formularz, by skontaktować się z nami",
    "meta": {
      "description": "Jesteśmy to Twojej dyspozycji, jeśli masz jakiekolwiek pytania. Skontaktuj się z nami.",
      "title": "Kontakt"
    },
    "offices": "Nasze biura",
    "title": "Potrzebujesz porady, konsultacji lub masz inne pytanie? "
  }
}
</i18n>
