<template>
  <SectionSplit src="../../assets/landing-finances/1.jpg">
    <h3>
      {{ $t('title') }}
    </h3>
    <p v-for="(paragraph, index) in $t('paragraphs')" :key="index" v-html="paragraph" />
  </SectionSplit>
</template>

<script>
import SectionSplit from 'views/components/SectionSplit'

export default {
  components: { SectionSplit },
}
</script>

<style lang="scss">
</style>

<i18n>
{
  "en": {
    "title": "How to invest?",
    "paragraphs": [
      "Contact the financial brokerage company/bank handling your securities account for the submission of an investment order.",
      "If you do not have an investment services agreement concluded with a financial intermediary, leave your contacts in <a href=\"https://www.orion.lt/en/sig-contact-form\" target=\"_blank\">the provided form</a> and Orion Securities investment consultant will contact you. Otherwise, consult by email to: <a href=\"mailto:obligacijos@orion.lt\" target=\"_blank\">obligacijos@orion.lt</a>",
      "We recommend that before making an investment decision, you consult with your financial advisor and evaluate all the risks associated with the financial instrument and/or other circumstances that are significant to you."
    ]
  },
  "lt": {
    "title": "Kaip investuoti?",
    "paragraphs": [
      "Norėdami investuoti – kreipkitės į Jūsų vertybinių popierių sąskaitą tvarkančią finansų maklerių įmonę/banką dėl investavimo pavedimo pateikimo.",
      "Jeigu nesate sudarę paslaugų sutarties su Lietuvoje veikiančiu finansų tarpininku, užpildykite <a href=\"https://www.orion.lt/sig-kontaktine-forma/\" target=\"_blank\">kontaktų formą</a> ir su Jumis susisieks „Orion“ investicijų konsultantai. Konsultuotis galite ir elektroniniu paštu <a href=\"mailto:obligacijos@orion.lt\" target=\"_blank\">obligacijos@orion.lt</a>.",
      "Rekomenduojame prieš priimant investicinį sprendimą pasitarti su Jūsų finansų konsultantu bei įvertinti visas su finansine priemone susijusias rizikas ir/ar kitas Jums reikšmingas aplinkybes."
    ]
  }
}
</i18n>
