<template>
  <section id="SectionGroup">
    <Wrap class="lg">
      <Headline>
        {{ $t('headline') }}
      </Headline>

      <div class="heading">
        <h2>
          {{ $t('title') }}
        </h2>
        <h3>
          {{ $t('header') }}
        </h3>
      </div>

      <SectionSplit
        v-for="(group, index) in groups"
        :key="index"
        class="group-item"
        :headline="group.name">
        <div slot="left" class="group-logo">
          <Pic
            :alt="group.name"
            :formats="['webp']"
            :src="`./assets/logos/${group.image}`" />
        </div>
        <p>
          {{ injectSnippets(group.description) }}
        </p>
        <a v-if="group.link" class="Btn inv sm" :href="`https://${group.link}`" target="_blank">
          {{ $t('visit') }}
        </a>
      </SectionSplit>
    </Wrap>
  </section>
</template>

<script>
import { getters, mergeLabels } from 'views/utils'
import SectionSplit from 'views/components/SectionSplit'

export default {
  components: { SectionSplit },
  data() {
    return {
      groupsLinks: [
        {
          name: 'Eternia Solar',
          image: 'logo-eternia.jpg',
          link: 'eternia.lt/',
        },
        {
          name: 'Eternia Services',
          image: 'logo-eternia-services.png',
        },
        {
          name: 'Saulės Bendruomenė',
          image: 'logo-saules-bendruomene.png',
          link: 'saulesbendruomene.lt',
        },
      ],
    }
  },
  computed: {
    groups() {
      return mergeLabels(this.groupsLinks, this
        .$ta('descriptions')
        .map(description => ({ description })))
    },
    ...getters('injectSnippets'),
  },
}
</script>

<style lang="scss">
#SectionGroup {
  background-color: $white;

  h2 {
    margin-bottom: 1rem;
    @include md {
      margin-bottom: 2rem;
    }
  }

  h3 {
    font-size: $h4;
    margin-bottom: 3rem;
    @include md {
      margin-bottom: 5rem;
    }
  }

  .SectionSplit {
    min-height: unset;
  }

  .group-item {
    margin: 2rem 0 4rem;
    @include md {
      margin: 4rem 0 8rem;
    }

    p {
      margin-bottom: 1rem;
    }
  }

  .group-logo {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  .Btn {
    width: 100%;
    @include md {
      width: unset;
    }
  }
}
</style>

<i18n>
{
  "de": {
    "descriptions": [
      "Eternia Solar ist einer der größten Anbieter von Solartechnik, Beschaffung und Bau in den baltischen Staaten. Das Unternehmen arbeitet eng mit internationalen Investoren und Kunden aus der Industrie, der Logistik, dem Einzelhandel, dem Immobiliensektor, der Landwirtschaft und dem Privatsektor zusammen, um eine langfristige Effektivität und einen effizienten Betrieb zu gewährleisten.",
      "Eternia Services bietet umfassende Ingenieurdienstleistungen an: in erster Linie Entwurf, Ertragsbewertung, Ausführungsplanung, Bestandsdokumentation. Das Team von Fachleuten kümmert sich um die Modernisierung von Systemen zur Ertragsoptimierung, die Bauüberwachung im Auftrag von Investoren und die Bereitstellung von Betriebs- und Wartungsdienstleistungen höchster Qualität, um die höchste Verfügbarkeit zu gewährleisten.",
      "Saulės Bendruomenė entwickelt, baut und wartet große Solarparks in ganz Litauen, die es sowohl B2B- als auch B2C-Kunden ermöglichen, Solarenergie aus der Ferne zu beziehen. Das erfahrene Team sorgt für die qualitativ hochwertige Ausführung jedes Projekts und garantiert die erfolgreiche Erzeugung grüner Energie für Kunden, die Solarenergie aus der Ferne beziehen."
    ],
    "header": "SIG ist Teil einer Unternehmensgruppe, die die ehrgeizigsten und erfahrensten Solarexperten in Europa vereint.",
    "headline": "Gruppe",
    "title": "Gemeinsam stärker",
    "visit": "Website besuchen"
  },
  "en": {
    "descriptions": [
      "Eternia Solar is one of the biggest solar engineering, procurement, and construction providers in the Baltics. The company works closely with international investors and clients from the manufacturing, logistics, retail, real estate, agriculture, private sectors to guarantee long-term effectiveness and efficient operation.",
      "Eternia Services provides comprehensive engineering services: primarily design, yield assessment, executive design, as-built documentation. The team of professionals takes care of revamping systems to optimize yields, construction supervision on behalf of investors, and providing the highest quality operations and maintenance services to ensure the highest availability.",
      "Saulės Bendruomenė develops, constructs, and maintains large-scale solar parks across Lithuania, allowing both B2B and B2C clients to purchase solar energy remotely. The experienced team ensures the high-quality execution of each project and guarantees successful green energy production for remote solar energy prosumers."
    ],
    "header": "SIG is part of a company group that unites the most ambitious and experienced solar experts in Europe.",
    "headline": "Group",
    "title": "Stronger together",
    "visit": "Visit website"
  },
  "it": {
    "descriptions": [
      "",
      "",
      ""
    ],
    "header": "",
    "headline": "",
    "title": "",
    "visit": ""
  },
  "lt": {
    "descriptions": [
      "Eternia Solar yra viena didžiausių atsinaujinančios energetikos rangos įmonių Baltijos šalyse. Įmonė glaudžiai bendradarbiauja su tarptautiniais investuotojais bei klientais iš pramonės, logistikos, žemės ūkio, nekilnojamo turto ir privataus sektoriaus tam, kad užtikrintų ilgalaikį saulės energetikos sprendimų efektyvumą ir sklandų veikimą.",
      "Eternia Services teikia inžinerines paslaugas: pradedant projektavimu, atsiperkamumo bei galimybių vertinimu ir baigiant visos reikiamos dokumentacijos tvarkymu. Profesionalų komanda rūpinasi aukščiausios kokybės operacijų bei priežiūros paslaugų teikimu, taip užtikrinant didžiausią saulės elektrinės efektyvumą.",
      "„Saulės bendruomenė“ vysto, stato ir prižiūri didelio masto saulės parkus, suteikiančius galimybę tiek privatiems, tiek verslo klientams naudoti saulės energiją, pagamintą geografiškai nutolusioje saulės elektrinėje. Patyrusi komanda užtikrina kokybišką saulės parkų įgyvendinimą ir sėkmingą žaliosios energijos generaciją savo klientams."
    ],
    "header": "SIG priklauso įmonių grupei, kuri vienija ambicingiausius ir labiausiai patyrusius saulės energetikos ekspertus.",
    "headline": "Grupė",
    "title": "Stipresni kartu",
    "visit": "Apsilankyti puslapyje"
  },
  "pl": {
    "descriptions": [
      "Eternia Solar jest jednym z największych dostawców technologii słonecznych w krajach bałtyckich. Firma ściśle współpracuje z międzynarodowymi inwestorami i klientami z sektorów produkcji, logistyki, handlu detalicznego, nieruchomości oraz rolnictwa, a to wszystko po to, aby zagwarantować długoterminową efektywność i sprawne działanie instalacji fotowoltaicznych.",
      "Eternia Services świadczy wszechstronne usługi inżynierskie od stworzenia projektu przez ocenę jego wydajności po przygotowanie pełnej dokumentacji. Zespół specjalistów stale dba o modernizację systemu w celu zoptymalizowania dostarczenia kompleksowych usług.",
      "Saulės Bendruomenė rozwija, buduje i utrzymuje duże parki słoneczne na Litwie, umożliwiając klientom B2B i B2C zdalny zakup energii słonecznej. Doświadczony zespół zapewnia wysoką jakość realizacji każdego projektu i gwarantuje udaną produkcję zielonej energii dla zdalnych prosumentów energii słonecznej."
    ],
    "header": "SIG jest częścią grupy kapitałowej, która zrzesza najbardziej doświadczonych i ambitnych ekspertów w dziedzinie energii słonecznej w Europie.",
    "headline": "Grupa",
    "title": "Siła grupy",
    "visit": "Odwiedź stronę"
  }
}
</i18n>
