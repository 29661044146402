<template>
  <div class="SectionSolution">
    <div v-if="name" class="head">
      <Icon v-if="icon" :name="icon.replace('_', '-')" />
      <h4 :class="{ long: name.length > 35 }">
        {{ name }}
      </h4>
    </div>
    <div class="list">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    name: String,
  },
}
</script>

<style lang="scss">
.SectionSolution {
  // margin-bottom: 4rem;

  // @include lg {
  //   margin-bottom: 8rem;
  // }

  > .head {
    margin-bottom: 2rem;
    overflow: hidden;

    > .Icon {
      color: $info;
      float: left;
      font-size: $h1;
      margin-right: 2rem;
    }

    > h4 {
      font-size: $h35;
      line-height: 4rem;
      vertical-align: top;

      &.long {
        line-height: 2.25rem;
      }
    }
  }

  > .list {
    h4 {
      margin: 2rem 0 1rem;
    }

    p {
      margin-bottom: 2rem;
    }

    ul {
      list-style: none;
      margin: 2rem 0;

      > li {
        color: $grey;
        margin-bottom: 1.5rem;
        padding-left: 2rem;
        position: relative;

        &::before {
          color: $text-color;
          content: "\2022";
          display: inline-block;
          font-weight: $bold;
          left: 0;
          position: absolute;
        }
      }
    }
  }
}
</style>
