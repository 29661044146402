<template>
  <div id="KeySuccessStory">
    <Hero class="about-us" src="../../assets/key-success-story-hero.jpg">
      <Headline class="white">
        {{ $t('headline') }}
      </Headline>
      <Row>
        <Column :lg="6">
          <h2>{{ $t('title') }}</h2>
        </Column>
        <Column :lg="6">
          <p>{{ $t('side') }}</p>
        </Column>
      </Row>
      <HeroScrollDown hash="#SectionOurApproach" slot="outside" />
    </Hero>
    <SectionOurApproach />
    <section id="section-numbers">
      <Wrap>
        <Row>
          <Column v-for="({ label, number }) in stats" :key="label" :md="4">
            <StatNumber :name="label" :number="number" />
          </Column>
        </Row>
      </Wrap>
    </section>
    <SectionResults />
  </div>
</template>

<script>
import Hero from 'views/components/Hero'
import HeroScrollDown from 'views/components/HeroScrollDown'
import Meta from 'views/mixins/Meta'
import SectionOurApproach from './components/SectionOurApproach'
import SectionResults from './components/SectionResults'
import StatNumber from 'views/components/StatNumber'
import { mergeLabels } from '../../utils'

export default {
  mixins: [
    Meta(require('@/assets/social/key-success-story.jpg')),
  ],
  components: { Hero, HeroScrollDown, StatNumber, SectionOurApproach, SectionResults },
  data() {
    return {
      statsValues: [
        { number: 400 },
        { number: 43 },
        { number: 42 },
      ],
    }
  },
  computed: {
    stats() {
      return mergeLabels(this.statsValues, this.$ta('stats'))
    },
  },
}
</script>

<style lang="scss">
#KeySuccessStory {
  #section-numbers {
    background-color: $white;
    padding: 10rem 0;

    .StatNumber {
      display: flex;
      justify-content: center;
      white-space: nowrap;

      .number {
        min-width: 125px;
        text-align: right;
        @include md {
          min-width: unset;
        }
      }

      .name {
        align-items: flex-end;
        display: flex;
        font-size: 1.125rem;
        font-weight: $semibold;
        margin-left: 1.5rem;
        padding-bottom: 1.25rem;
        white-space: pre-line;
        width: 125px;

        &::after {
          @include lg {
            border-bottom: 3px solid $blue;
            bottom: -1rem;
            content: '';
            position: absolute;
            width: 2.25rem;
          }
        }
      }
    }
  }
}
</style>

<i18n>
{
  "de": {
    "headline": "",
    "meta": {
      "description": "",
      "title": ""
    },
    "side": "",
    "stats": [
      "",
      "",
      ""
    ],
    "title": ""
  },
  "en": {
    "headline": "Key success story",
    "meta": {
      "description": "Most of Poland’s electricity is still generated by coal, but that is destined to change soon. The European Union is demanding at least 20% of the country’s energy supplies to be renewable by 2020.",
      "title": "Key Success Story"
    },
    "side": "Most of Poland’s electricity is still generated by coal, but that is destined to change soon. The European Union is demanding at least 20% of the country’s energy supplies to be renewable by 2020 and the local government is, in turn, promoting solar energy development. While Poland’s solar capacity is projected to grow at a pace of 400 to 500 MWp per year, the country’s solar market is still at the beginning of the transformation, making it an attractive niche market for investors.",
    "stats": [
      "Growth per year, MWp",
      "Ongoing projects",
      "Cumulative power, MWp"
    ],
    "title": "The biggest solar portfolio in Poland"
  },
  "it": {
    "headline": "",
    "meta": {
      "description": "",
      "title": ""
    },
    "side": "",
    "stats": [
      "",
      "",
      ""
    ],
    "title": ""
  },
  "lt": {
    "headline": "Mūsų sėkmės istorija",
    "meta": {
      "description": "Didžioji dalis Lenkijoje sugeneruotos energijos yra išgaunama iš anglies, bet tai netrukus pasikeis. Europos Sąjunga reikalauja iki 2020-ųjų bent 20% energijos išgauti iš atsinaujinančių energijos šaltinių. ",
      "title": "Mūsų sėkmės istorija"
    },
    "side": "Didžioji dalis Lenkijoje sugeneruotos energijos yra išgaunama iš anglies, bet tai netrukus pasikeis. Europos Sąjunga reikalauja iki 2020-ųjų bent 20% energijos išgauti iš atsinaujinančių energijos šaltinių, o vietos valdžia skatina saulės energetikos plėtrą. Nors numatoma, kad Lenkijos saulės energetikos sektorius augs 400-500 MWp kasmet, šalis vis dar yra pradiniuose transformacijos etapuose, todėl suteikia daug galimybių investuotojams. ",
    "stats": [
      "Kasmetinis augimas, MWp",
      "Vykdomi projektai",
      "Bendra galia, MWp"
    ],
    "title": "Daugiausia Lenkijoje įgyvendintų saulės energetikos projektų"
  },
  "pl": {
    "headline": "Historia naszego sukcesu",
    "meta": {
      "description": "Większość energii elektrycznej w Polsce nadal wytwarza się z węgla, ale sytuacja powoli  się zmienia. Unia Europejska wymaga, by do 2020 r. co najmniej 20% energii w Polsce pochodziło ze źródeł odnawialnych.",
      "title": "Historia naszego sukcesu"
    },
    "side": "Większość energii elektrycznej w Polsce nadal wytwarza się w Polsce z węgla, ale sytuacja wkrótce się zmieni. Unia Europejska wymaga, by do 2020 r. co najmniej 20% energii w Polsce pochodziło ze źródeł odnawialnych. Samorządy z kolei promują rozwój energii słonecznej. Chociaż prognozuje się, że ilość energii wytwarzanej w kolektorach słonecznych będzie wzrastać w tempie od 400 do 500 MWp rocznie, polski rynek energii słonecznej wciąż znajduje się w początkowym okresie transformacji, co czyni go atrakcyjnym dla inwestorów.",
    "stats": [
      "Roczny wzrost w MWp",
      "Bieżące projekty",
      "Skumulowana moc w MWp"
    ],
    "title": "Największe portfolio projektów solarnych w Polsce"
  }
}
</i18n>
