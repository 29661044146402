<template>
  <div v-show="isActive">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    name: {
      required: true,
      type: String,
    },
    isSelected: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isActive: false,
    }
  },
  mounted() {
    this.isActive = this.isSelected
  },
}
</script>
