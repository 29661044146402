<template>
  <section id="SectionPress">
    <Wrap>
      <h2 class="title">
        {{ $t('spotlight') }}
      </h2>
      <Row v-for="(row, index) in pressRows" :key="index">
        <Column v-for="post in row" :key="post.id" :lg="4">
          <CardPress :press="post" />
        </Column>
      </Row>
      <PaginationShowMore
        v-if="pressCount > limitPress"
        :limit.sync="limitPress"
        name="press"
        :total="pressCount" />
    </Wrap>
  </section>
</template>

<script>
import { actions, getters } from 'views/utils'
import CardPress from 'views/components/CardPress'
import PaginationShowMore from 'views/components/PaginationShowMore'

export default {
  components: { CardPress, PaginationShowMore },
  data() {
    return {
      limitPress: 9,
    }
  },
  computed: {
    press() {
      return this.query('press', {
        $limit: this.limitPress,
        $sort: { id: -1 },
      })
    },
    pressCount() {
      return this.query('press').length
    },
    pressRows() {
      return this.splitIntoRows(this.press, {
        lg: 3, // split into 3 entries per row
        md: 2, // split into 2 entries per row
        xs: 1, // split into 1 entry per row
      })
    },
    ...getters('splitIntoRows'),
  },
  methods: actions('FETCH_PRESS'),
  created() {
    this.LOAD(this.FETCH_PRESS())
  },
}
</script>

<style lang="scss">
#SectionPress {
  .title {
    display: block;
    margin-bottom: 3.5rem;
  }
}
</style>

<i18n>
{
  "de": {
    "spotlight": "Im Rampenlicht der Medien"
  },
  "en": {
    "spotlight": "In the media spotlight"
  },
  "it": {
    "spotlight": ""
  },
  "lt": {
    "spotlight": "Mes spaudoje"
  },
  "pl": {
    "spotlight": "Media o nas"
  }
}
</i18n>
