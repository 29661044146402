<template>
  <section v-if="blogs.length" id="SectionBlog">
    <Wrap>
      <Headline>
        {{ $t('headline') }}
      </Headline>
      <h2>{{ $t('blog') }}</h2>
      <WrapScroll>
        <Column v-for="blog in blogs" :key="blog.id" class="item" :lg="4">
          <CardBlogSmall :blog="blog" />
        </Column>
        <template #button>
          <Route class="Btn sm inv" to="Blog">
            {{ $t('all') }}
          </Route>
        </template>
      </WrapScroll>
    </Wrap>
  </section>
</template>

<script>
import CardBlogSmall from 'views/components/CardBlogSmall'
import WrapScroll from 'views/components/WrapScroll'
import { getters } from 'views/utils'

export default {
  components: { CardBlogSmall, WrapScroll },
  computed: {
    blogs() {
      return this.query('blog', {
        $or: [
          { locale: this.locale },
          { showOnAllLocales: true },
        ],
        $limit: 10,
        $sort: {
          // sorting by boolean do not work
          // featured: -1,
          created_at: -1,
        },
      })
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
#SectionBlog {
  h2 {
    margin-bottom: 4rem;
  }

  .CardBlogSmall {
    white-space: normal;
  }
}
</style>

<i18n>
{
  "de": {
    "all": "Alle Blogbeiträge",
    "blog": "Wir haben Geschichten zu erzählen",
    "headline": "Blog"
  },
  "en": {
    "all": "All blog posts",
    "blog": "We have stories to tell",
    "headline": "Blog"
  },
  "it": {
    "all": "",
    "blog": "",
    "headline": ""
  },
  "lt": {
    "all": "Visi įrašai",
    "blog": "Dalinamės istorijomis",
    "headline": "Tinklaraštis"
  },
  "pl": {
    "all": "wszystkie posty na blogu",
    "blog": "Nasze historie",
    "headline": "Blog"
  }
}
</i18n>
