<template>
  <div class="TeamMemberImage">
    <Pic
      :src="(member.image[0] || member.image).url" />
    <a
      class="linkedin"
      :href="member.linkedin"
      rel="noopener"
      target="_blank"
      :title="`${member.name} LinkedIn`">
      <Icon name="linkedin" />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    member: Object,
  },
}
</script>

<style lang="scss">
.TeamMemberImage {
  margin: auto;
  position: relative;
  width: fit-content;

  img {
    object-fit: cover;
    width: 100%;
  }

  > .linkedin {
    bottom: 2rem;
    color: $white;
    font-size: $h5;
    position: absolute;
    right: 2rem;
  }
}
</style>
