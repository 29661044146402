<template>
  <section id="SectionOurOffice">
    <Wrap>
      <Headline>
        {{ $t('headline') }}
      </Headline>
      <h2>{{ $t('title') }}</h2>
      <p class="description">
        {{ $t('description') }}
      </p>
      <Row>
        <Column v-for="perk in perks" :key="perk.icon" :md="3">
          <div class="perk">
            <Icon :name="perk.icon" />
            {{ perk.label }}
          </div>
        </Column>
      </Row>
    </Wrap>
  </section>
</template>

<script>
import { mergeLabels } from 'views/utils'

export default {
  data() {
    return {
      perksValues: [
        { icon: 'fruit' },
        { icon: 'coffee' },
        { icon: 'trophy' },
        { icon: 'underwear' },
      ],
    }
  },
  computed: {
    perks() {
      return mergeLabels(this.perksValues, this.$ta('perks'))
    },
  },
}
</script>

<style lang="scss">
#SectionOurOffice {
  background-color: $white;
  overflow: hidden;

  h2 {
    margin-bottom: 3.5rem;
  }

  .perk {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    text-align: center;
    @include md {
      margin-bottom: 0;
    }

    .Icon {
      font-size: 6rem;
    }
  }

  .description {
    margin-bottom: 5rem;
  }
}
</style>

<i18n>
{
  "de": {
    "description": "Wenn Sie Teil eines aufregenden und innovativen Unternehmens sein wollen, bieten wir Ihnen ein wettbewerbsfähiges Gehalt und ein Paket von Zusatzleistungen. Dies sind einige der Annehmlichkeiten, die Sie erwarten können",
    "headline": "Unser Büro",
    "perks": [
      "Frisches Obst & gesunde Snacks",
      "Leckerer Kaffee",
      "Spiele ",
      "Lässiger Stil"
    ],
    "title": "Fühlt sich an wie zu Hause"
  },
  "en": {
    "description": "If you want to be a part of an exciting and innovative company, we provide a competitive salary and benefits package. These are some of the perks you can expect.",
    "headline": "Our office",
    "perks": [
      "Fresh fruits & Healthy snacks",
      "Delicious coffee",
      "Games",
      "Casual style"
    ],
    "title": "Feels like home"
  },
  "it": {
    "description": "",
    "headline": "",
    "perks": [
      "",
      "",
      "",
      ""
    ],
    "title": ""
  },
  "lt": {
    "description": "Tapkite veržlios ir inovatyvios įmonės dalimi, o mes užtikrinsime konkurencingą atlyginimą ir kitus privalumus. Tarp jų:",
    "headline": "Mūsų biuras",
    "perks": [
      "Švieži vaisiai ir sveiki užkandžiai",
      "Gardi kava",
      "Žaidimai",
      "Neformalus stilius"
    ],
    "title": "Namus primenanti aplinka"
  },
  "pl": {
    "description": "Chesz być częścią innowacyjnej firmy? Zapewniamy konkurencyjny pakiet wynagrodzeń i benefitów, a to kilka z nich:",
    "headline": "Nasze biuro",
    "perks": [
      "Świeże owoce i zdrowe przekąski",
      "Pyszna kawa",
      "Gry",
      "Casualowy styl"
    ],
    "title": "Poczuj się jak w domu"
  }
}
</i18n>
