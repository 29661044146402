<template>
  <div id="SectionResults">
    <div class="SectionSplit">
      <Splitter :left="5" :right="7">
        <div slot="right">
          <Headline>{{ $t('headline') }}</Headline>
          <h3>{{ $t('title') }}</h3>
          <p>
            {{ $t('content') }}
          </p>
        </div>
        <div class="body" slot="left">
          <div class="map-container">
            <MapResults
              @unzoomed="showCount = false"
              @update:region="onRegionZoomed" />
          </div>

          <StatNumber
            v-if="showCount"
            :name="name"
            :number="number"
            :suffix="suffix" />
        </div>
      </Splitter>
    </div>
  </div>
</template>

<script>
import { actions, getters } from 'views/utils'
import Splitter from 'views/components/Splitter'
import StatNumber from 'views/components/StatNumber'

const MapResults = () => import(/* webpackChunkName: 'leaflet' */ './MapResults')

export default {
  components: {
    Splitter,
    MapResults,
    StatNumber,
    // Map,
  },
  data() {
    return {
      showCount: false,
      name: '',
      number: 0,
      suffix: 'PROJECTS',
      activeCountryId: 1,
      activeOfficeId: 0,
      activePlantId: 82,
    }
  },
  computed: {
    ...getters('isLoad'),
    country() {
      return this.query('country', {
        id: this.activeCountryId,
        locale: this.locale,
      }, true)
    },
  },
  methods: {
    ...actions('FETCH_COUNTRY'),
    onRegionZoomed(region) {
      this.number = region.plants.length
      this.name = 'In ' + region.name
      this.showCount = true
    },
  },
  created() {
    this.LOAD(this.FETCH_COUNTRY())
  },
}
</script>

<style lang="scss">
#SectionResults {
  background-color: $black-bis;
  background-image: url("../../../../assets/ellipses.png");
  background-position: top right;
  background-repeat: no-repeat;
  min-height: 100vh;
  padding: 1rem;
  @include sm {
    padding: 2rem;
  }

  .MapResults {
    padding: 5rem 5rem 5rem 15rem;
  }

  .StatNumber {
    .affix {
      color: $white;
      font-size: 1rem;
      font-weight: $semibold;
      padding: 0 1rem;
    }

    .number {
      font-size: 5rem;
    }

    .name {
      color: $white;
    }
  }

  .SectionSplit {
    @include lg {
      flex-direction: row;
      min-height: 100vh;
    }

    h3 {
      color: $white;
      margin-bottom: 1.5rem;
    }

    p {
      color: $grey;
    }

    .map-container {
      display: block;
      height: 40vh;
      @include sm {
        height: 50vh;
      }
      @include lg {
        height: 100vh;
      }
    }
  }
}
</style>

<i18n>
{
  "de": {
    "content": "",
    "headline": "",
    "title": ""
  },
  "en": {
    "content": "The solar arrays, dispersed all across the country’s territory, are due to be connected to the grid in 2019. All of the projects are fully ready to build, with EPC and O&M options in place. With building permits secured and 15-year CfD tariffs granted in the official auction, our success in Poland ensures a long-term fixed energy sale with a government guarantee.",
    "headline": "The Results",
    "title": "Our Poland portfolio consists of 43 projects, each up to 1MWp in size."
  },
  "it": {
    "content": "",
    "headline": "",
    "title": ""
  },
  "lt": {
    "content": "Šalyje įrengti saulės moduliai buvo prijungti prie tinklo 2019 metais. Mūsų projektai Lenkijoje užtikrina ilgalaikę, stabilią, valstybės reglamentuotą grąžą, nes Lenkijos saulės parkų generuojamos energijos kaina yra garantuota 15 metų nekintančiu tarifu, nustatytu oficialaus aukciono metu. ",
    "headline": "Rezultatai",
    "title": "Įgyvendinome 43 projektus, kurių galia siekia iki 1MW."
  },
  "pl": {
    "content": "Instalacje słoneczne, rozproszone na terytorium całego kraju, mają zostać podłączone do sieci energetycznej w 2019 r. Wszystkie projekty są gotowe do realizacji w formule EPC i O&M. Dzięki zagwarantowanym pozwoleniom na budowę i 15-letnim taryfom w oparciu o kontrakty CFD przyznanym na oficjalnej aukcji, nasz sukces w Polsce zapewnia długoterminową, stałą sprzedaż energii z gwarancjami rządowymi.",
    "headline": "Rezultaty",
    "title": "Nasze portfolio projektów realizowanych w Polsce obejmuje 43 projekty, każdy o mocy do 1MWp."
  }
}
</i18n>
