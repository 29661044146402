<template>
  <SectionSplit class="SectionPresentation reverse" src="../../assets/landing-finances/presentation.jpg">
    <h3>
      {{ $t('title') }}
    </h3>
    <p v-for="(paragraph, index) in $t('paragraphs')" :key="index">
      {{ paragraph }}
    </p>
    <a class="Btn lg" :href="videoLink" target="_blank">
      {{ $t('watch') }}
    </a>
  </SectionSplit>
</template>

<script>
import SectionSplit from 'views/components/SectionSplit'
import { getters } from 'views/utils'

export default {
  components: { SectionSplit },
  data() {
    return {
      videoLinkLt: 'https://youtu.be/4FSsOuMJQts',
      videoLinkEn: 'https://youtu.be/d68WT0TDpB8',
    }
  },
  computed: {
    videoLink() {
      return this.locale === 'lt'
        ? this.videoLinkLt
        : this.videoLinkEn
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
.SectionPresentation {
  min-height: 60vh;
  @include lg {
    flex-direction: row;
    min-height: 60vh;
  }

  .image {
    display: block;
    height: 30vh;
    @include lg {
      height: 60vh;
    }

    > img {
      height: 100%;
      object-fit: cover;
      object-position: top;
      width: 100%;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "title": "Video presentation",
    "paragraphs": [
      "Sun Investment Group CEO Deividas Varabauskas and Orion Securities Head of Corporate Finance Mykantas Urba present Sun Invesment Group bond issues and answer investor questions."
    ],
    "watch": "Watch video"
  },
  "lt": {
    "title": "Video pristatymas",
    "paragraphs": [
      "„Sun Investment Group” vadovas Deividas Varabauskas ir „Orion” įmonių finansų skyriaus vadovas Mykantas Urba pristato šiuo metu platinamos „Sun Investment Group” obligacijų emisijos detales ir atsako į investuotojų klausimus."
    ],
    "watch": "Peržiūrėti"
  }
}
</i18n>
