<template>
  <Row class="SectionTitle">
    <Column :sm="8">
      <h2>{{ title }}</h2>
    </Column>
    <Column v-if="$slots.default" :sm="4">
      <div class="filters">
        <slot />
      </div>
    </Column>
  </Row>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
  },
}
</script>

<style lang="scss">
.SectionTitle {
  align-items: center;
  margin-bottom: 4rem;
  @include md {
    margin-bottom: 7rem;
  }

  .filters {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1rem;
    white-space: nowrap;
    @include sm {
      justify-content: flex-end;
      margin-top: 0;
    }

    .Btn {
      margin-bottom: 0.5rem;
      margin-right: 1rem;

      &.active {
        background: $white-ter;
        border-color: $white-ter;
      }
    }
  }

  .countries {
    display: inline-block;
    margin-bottom: 0;
    max-width: 12rem;

    .type-select {
      border-bottom: none;
      font-size: 1rem;
    }
  }
}
</style>
