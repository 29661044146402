<template>
  <Row class="ProgressionSteps no-gutters">
    <Column
      v-for="({ title, text }, index) in steps"
      :key="index"
      :md="12 / steps.length">
      <ProgressionStep :text="text" :title="title" />
      <div v-if="hasArrows && index !== steps.length - 1" class="arrow">
        <Icon :name="isDesktop ? 'angle-right' : 'angle-down'" />
      </div>
    </Column>
  </Row>
</template>

<script>
import ProgressionStep from './ProgressionStep'
import { getters } from 'views/utils'

export default {
  components: { ProgressionStep },
  props: {
    hasArrows: Boolean,
    steps: Array,
  },
  computed: getters('isDesktop'),
}
</script>

<style lang="scss">
.ProgressionSteps {
  padding: 0 1.5rem;
  @include md {
    padding: 0;
  }

  .arrow {
    bottom: 2rem;
    color: $main;
    font-size: 2rem;
    pointer-events: none;
    position: absolute;
    right: 50%;
    text-align: center;
    transform: translateX(1rem);
    @include md {
      bottom: auto;
      font-size: 2rem;
      right: -25%;
      top: 0;
      transform: none;
    }
    @include lg {
      right: -20%;
      top: 0.5rem;
    }

    &::before {
      @include md {
        background: $main;
        content: ' ';
        display: block;
        height: 2px;
        left: -3rem;
        position: absolute;
        top: 0.9rem;
        width: 4.25rem;
      }
      @include lg {
        top: 0.95rem;
      }
      @include xl {
        left: -5rem;
        width: 6.25rem;
      }
    }
  }
}
</style>
