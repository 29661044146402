<template>
  <section class="GeneralDocuments">
    <Wrap class="lg">
      <Row>
        <Column :md="6">
          <p>
            {{ $t('description') }}
            <br>
            <a class="Btn lg" :href="documentsLocations.description" target="_blank">
              {{ $t('read') }}
            </a>
          </p>
          <p>
            {{ $t('articles') }}
            <br>
            <a class="Btn lg" :href="documentsLocations.articles" target="_blank">
              {{ $t('read') }}
            </a>
          </p>
        </Column>
        <Column :md="6">
          <p>
            {{ $t('statementsConsolidated') }}
            <br>
            <a class="Btn lg" :href="documentsLocations.statementsConsolidated2021" target="_blank">
              2021
            </a>
            <br>
            <a class="Btn lg" :href="documentsLocations.statementsConsolidated2022" target="_blank">
              2022
            </a>
          </p>
          <p>
            {{ $t('statementsConsolidatedPreliminary') }}
            <br>
            <a class="Btn lg" :href="documentsLocations.statementsConsolidated" target="_blank">
              2023
            </a>
          </p>
          <hr>
          <p>
            {{ $t('statementsStandalone') }}
            <br>
            <a class="Btn lg" :href="documentsLocations.statementsStandalone2021" target="_blank">
              2021
            </a>
            <br>
            <a class="Btn lg" :href="documentsLocations.statementsStandalone2022" target="_blank">
              2022
            </a>
          </p>
          <p>
            {{ $t('statementsStandalonePreliminary') }}
            <br>
            <a class="Btn lg" :href="documentsLocations.statementsStandalone" target="_blank">
              2023
            </a>
          </p>
        </Column>
      </Row>
    </Wrap>
  </section>
</template>

<script>
import { getters } from 'views/utils'
import { webUrl } from '@/config'

export default {
  data() {
    return {
      webUrl,
      folderPath: '/assets/public-bond-issue/general/',
      // documentsFilenames: {
      //   en: {
      //     description: 'descriptionEN.pdf',
      //     articles: 'articlesEN.pdf',
      //     statements2021: 'statements2021EN.pdf',
      //     statements2022: 'statements2022EN.pdf',
      //     statementsConsolidated: 'statementsConsolidatedEN.pdf',
      //     statementsStandalone2021: 'statementsStandalone2021EN.pdf',
      //     statementsStandalone2022: 'statementsStandalone2022EN.pdf',
      //     statementsPreliminary: 'statementsPreliminaryEN.pdf',
      //   },
      //   lt: {
      //     description: 'descriptionLT.pdf',
      //     articles: 'articlesLT.pdf',
      //     statements2021: 'statements2021LT.pdf',
      //     statements2022: 'statements2022LT.pdf',
      //     statementsConsolidated: 'statementsConsolidatedLT.pdf',
      //     statementsStandalone2021: 'statementsStandalone2021LT.pdf',
      //     statementsStandalone2022: 'statementsStandalone2022LT.pdf',
      //     statementsPreliminary: 'statementsPreliminaryLT.pdf',
      //   },
      // },
      documentsFilenames: {
        en: {
          description: 'en/Sun-Investment-Group_Bonds_Information-Document.pdf',
          articles: 'en/Articles of Association.pdf',
          statementsConsolidated2021: 'en/Consolidated_SIG_UAB_2021_ENG_audited.pdf',
          statementsConsolidated2022: 'en/Consolidated_SIG_UAB_2022 EN_audited.pdf',
          statementsConsolidated: 'en/SIG UAB 20230630_EN_Consolidated.pdf',
          statementsStandalone2021: 'en/Standalone SIG_UAB_2021_ENG_audited.pdf',
          statementsStandalone2022: 'en/Standalone_SIG_UAB_2022_EN_audited.pdf',
          statementsStandalone: 'en/SIG_Standalone_2023_IH_ENG_BSPL_translation_1.pdf',
        },
        lt: {
          description: 'en/Sun-Investment-Group_Bonds_Information-Document.pdf',
          articles: 'lt/Bendroves istatai.pdf',
          statementsConsolidated2021: 'lt/Consolidated_SIG_UAB_ 2021_LT_audited.pdf',
          statementsConsolidated2022: 'lt/Consolidated_SIG_UAB_2022_LT_audited.pdf',
          statementsConsolidated: 'lt/SIG UAB 20230630_EN_Consolidated.pdf',
          statementsStandalone2021: 'lt/Standalone SIG_UAB_2021_LT_audited.pdf',
          statementsStandalone2022: 'lt/Standalone_SIG UAB_2022_LT_audited.pdf',
          statementsStandalone: 'en/SIG_Standalone_2023_IH_ENG_BSPL_translation_1.pdf',
        },
      },
    }
  },
  computed: {
    documentsLocations() {
      const filenames = this.documentsFilenames[this.locale] || this.documentsFilenames.en

      return Object.fromEntries(Object.entries(filenames)
        .map(filename => [filename[0], `${this.webUrl}${this.folderPath}${filename[1]}`]))
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
.GeneralDocuments {
  p {
    font-size: $h35;
    padding-bottom: 3rem;

    .Btn {
      font-size: $h4;
      margin: 1.5rem 0;
      min-width: 15rem;
    }
  }

  hr {
    margin-bottom: 2rem;
  }
}
</style>

<i18n>
{
  "en": {
    "read": "Read",
    "description": "Information document",
    "articles": "Articles of Association",
    "statementsConsolidated": "2022 & 2021: Sets of consolidated audited financial statements",
    "statementsConsolidatedPreliminary": "2023: Consolidated preliminary financial statements",
    "statementsStandalone": "2022 & 2021: Sets of audited standalone financial reports",
    "statementsStandalonePreliminary": "2023: Standalone preliminary financial statements"
  },
  "lt": {
    "read": "Skaityti",
    "description": "Informacinis dokumentas (ENG)",
    "articles": "Bendrovės įstatai",
    "statementsConsolidated": "Konsoliduotų audituotų finansinių ataskaitų rinkiniai",
    "statementsConsolidatedPreliminary": "Konsoliduotos preliminarios finansinės ataskaitos (pelno, nuostolių bei balanso ataskaitos) (ENG)",
    "statementsStandalone": "Įmonės audituotų finansinių ataskaitų rinkiniai",
    "statementsStandalonePreliminary": "Įmonės preliminarios finansinės ataskaitos (ENG)"
  }
}
</i18n>
