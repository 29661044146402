<template>
  <div class="TeamMemberSmall">
    <TeamMemberImage :member="member" />
    <Wrap class="body">
      <div class="head">
        <h4>
          {{ member.name }}
        </h4>
        <div class="role">
          {{ member.role }}
        </div>
      </div>
      <p>
        {{ member.description }}
      </p>
    </Wrap>
  </div>
</template>

<script>
import TeamMemberImage from './TeamMemberImage'

export default {
  components: { TeamMemberImage },
  props: {
    member: Object,
  },
}
</script>

<style lang="scss">
.TeamMemberSmall {
  background: $white;
  height: 100%;

  > .TeamMemberImage {
    max-height: 26rem;
    overflow: hidden;

    img {
      @include lg {
        object-fit: none;
        object-position: top;
      }
    }
  }

  > .body {
    max-width: 40rem;
    padding: 2rem 2rem 1rem;
    @include md {
      max-width: auto1;
    }

    > .head {
      margin: 1rem 0 2rem;

      .role {
        margin-top: 0.75rem;
        opacity: 0.75;
      }
    }

    p {
      margin-bottom: 2rem;
      opacity: 0.5;
    }
  }
}
</style>
