<template>
  <SectionSplit class="reverse" :headline="$t('headline')" src="../../assets/focus.jpg">
    <h3>
      {{ $t('title') }}
    </h3>
    <p>
      <ul>
        <li v-for="country in $t('countries')" :key="country">
          {{ country }}
        </li>
      </ul>
    </p>
    <p>
      {{ $t('divisions.title') }}
      <ul>
        <li v-for="(division, index) in $t('divisions.list')" :key="index">
          {{ division }}
        </li>
      </ul>
    </p>
    <p>
      {{ $t('together') }}
    </p>
  </SectionSplit>
</template>

<script>
import SectionSplit from 'views/components/SectionSplit'

export default {
  components: { SectionSplit },
}
</script>

<style lang="scss">
</style>

<i18n>
{
  "de": {
    "countries": [
      "Polen",
      "Litauen",
      "Spanien",
      "Italien",
      "Deutschland"
    ],
    "divisions": {
      "list": [
        "Entwicklung und Betrieb von C&I Projekten.",
        "EPCm (Baumanagement) nur für eigene Projekte."
      ],
      "title": "Wir haben die folgenden Kerngeschäftsbereiche:"
    },
    "headline": "Unser Fokus",
    "title": "Wir konzentrieren uns auf die folgenden Schlüsselmärkte",
    "together": "Zusammen bilden SIG und ihre Unternehmen eine der dynamischsten Unternehmensgruppen, die sich der Solar PV Technologie in Europa widmen, angeführt von den Gründern mit über 10 Jahren Erfahrung im Markt. Entwicklung & Betrieb."
  },
  "en": {
    "countries": [
      "Poland",
      "Lithuania",
      "Spain",
      "Italy",
      "Germany"
    ],
    "divisions": {
      "list": [
        "Utility Scale project development & operation.",
        "C&I PPA project development & operation.",
        "EPCm (construction management) for in-house projects only."
      ],
      "title": "And we have the following core business divisions:"
    },
    "headline": "Our focus",
    "title": "We focus on the following key markets",
    "together": "Together SIG and its companies form one of the most dynamic company groups dedicated to solar PV technology in Europe, led by the founders with over 10 years of experience in the market."
  },
  "it": {
    "countries": [
      "",
      "",
      "",
      "",
      ""
    ],
    "divisions": {
      "list": [
        "",
        "",
        ""
      ],
      "title": ""
    },
    "headline": "",
    "title": "",
    "together": ""
  },
  "lt": {
    "countries": [
      "Lenkija",
      "Lietuva",
      "Ispanija",
      "Italija",
      "Vokietija"
    ],
    "divisions": {
      "list": [
        "Pramoninio masto projektų vystymas ir įgyvendinimas.",
        "Komercinių energijos pirkimo sutarčių projektų vystymas ir įgyvendinimas.",
        "Vidinių statybos projektų valdymas."
      ],
      "title": "Pagrindinės įmonės veiklos sritys: "
    },
    "headline": "Mūsų tikslas",
    "title": "Mūsų pagrindinės rinkos yra",
    "together": "SIG ir grupės įmonės kartu sudaro vieną dinamiškiausių ir sparčiausiai augančių saulės energetikos technologijų grupių Europoje."
  },
  "pl": {
    "countries": [
      "Polska",
      "Litwa",
      "Hiszpania",
      "Włochy",
      "Niemcy"
    ],
    "divisions": {
      "list": [
        "Opracowywanie i obsługa projektów na skalę przemysłową.",
        "C&I Opracowywanie i realizacja projektów w ramach umów na zakup energii (PPA - power purchase agreement).",
        "Zarządzanie projektami budowlanymi realizowanymi przez firmy z Grupy SIG."
      ],
      "title": "Podstawowe obszary działalności firmy:"
    },
    "headline": "Nasz cel",
    "title": "Koncentrujemy się na następujących, kluczowych rynkach",
    "together": "SIG i jej spółki zależne tworzą jedną z najbardziej dynamicznych grup kapitałowych zajmujących się energetyką słoneczną. Firmą cały czas kierują jej założyciele posiadający ponad 10-letnie doświadczenie na rynku."
  }
}
</i18n>
