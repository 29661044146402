<template>
  <section id="SectionBusinessModel">
    <Wrap class="lg">
      <h3>
        {{ $t('title') }}
      </h3>
      <Tabs>
        <Tab
          v-for="(tab, index) in $ta('progressions')"
          :key="tab.name"
          :isSelected="!index"
          :name="tab.name">
          <ProgressionSteps
            :class="index ? 'inv' : ''"
            :hasArrows="true"
            :steps="Object.values(tab.steps)" />
        </Tab>
      </Tabs>
    </Wrap>
  </section>
</template>

<script>
import ProgressionSteps from './ProgressionSteps'
import Tab from './Tab'
import Tabs from './Tabs'

export default {
  components: { ProgressionSteps, Tab, Tabs },
}
</script>

<style lang="scss">
#SectionBusinessModel {
  align-content: center;
  align-items: center;
  background-color: $white;
  display: flex;
  min-height: 80vh;

  h3 {
    margin-bottom: 2rem;
    text-align: center;
    @include md {
      margin-bottom: 3rem;
      text-align: left;
    }
  }

  .Tabs ul {
    margin-bottom: 4rem;
    text-align: center;
    @include md {
      text-align: left;
    }

    li {
      font-size: $h4;
      line-height: 3rem;
      @include md {
        font-size: $h5;
      }
    }
  }

  .ProgressionSteps {
    margin-top: 2rem;

    &.inv {
      .default-icon {
        background: mix($black, $main);

        &::before {
          background: $main;
        }
      }
    }
  }
}
</style>

<i18n>
{
  "de": {
    "progressions": [
      {
        "name": "Unsere Planung",
        "steps": [
          {
            "text": "Standortanalyse und Anlagendesign, Sicherung des bestmoeglichen Netzanschlusses, Genehmigungsprozess und Sicherung der bestmoeglichen Vermarktung.",
            "title": "Entwicklung"
          },
          {
            "text": "Strukturierung und Beschaffung von Projektfinanzierung.",
            "title": "Finanzierung"
          },
          {
            "text": "EPC-Management, Nutzung der Erfahrung und Garantiestärke von SIG’s Netzwerk vertrauenswuerdiger Subunternehmer.",
            "title": "Bau"
          }
        ]
      },
      {
        "name": "Unsere Eigeninvestition",
        "steps": [
          {
            "text": "Technische Analyse, wirtschaftliche und rechtliche Bewertung",
            "title": "Standortbewertung"
          },
          {
            "text": "Schlüsselfertige Lösung (EPC) - Formalitäten, Planung und Bau",
            "title": "Planung und Bau"
          },
          {
            "text": "Optimierter Einsatz von Eigen- und Fremdkapital",
            "title": "Eigeninvestition"
          },
          {
            "text": "Optimierung, 24/7 Überwachung, Wartung, Versicherung und Garantien.",
            "title": "Betrieb und Wartung (O&M)"
          }
        ]
      }
    ],
    "title": "Geschäftsmodel"
  },
  "en": {
    "progressions": [
      {
        "name": "Utility",
        "steps": [
          {
            "text": "Every stage of project development from land preparation to the final handover (RTB).",
            "title": "Development"
          },
          {
            "text": " Eternia Solar, the EPC subsidiary of SIG, oversees the project's progression from the Ready to Build (RTB) phase to the Commissioning and Operational Date (COD).",
            "title": "Construction"
          },
          {
            "text": "Optimisation, 24/7 monitoring, maintenance, insurance and guarantees.",
            "title": "Operation and Maintenance (O&M)"
          }
        ]
      }
    ],
    "title": "Business model"
  },
  "it": {
    "progressions": [
      {
        "name": "",
        "steps": [
          {
            "text": "",
            "title": ""
          },
          {
            "text": "",
            "title": ""
          },
          {
            "text": "",
            "title": ""
          }
        ]
      },
      {
        "name": "",
        "steps": [
          {
            "text": "",
            "title": ""
          },
          {
            "text": "",
            "title": ""
          },
          {
            "text": "",
            "title": ""
          },
          {
            "text": "",
            "title": ""
          }
        ]
      }
    ],
    "title": ""
  },
  "lt": {
    "progressions": [
      {
        "name": "Pramoninio masto projektai",
        "steps": [
          {
            "text": "Investicinių projektų vystymas ir projektavimas, prijungimas prie tinklo, leidimų gavimas ir elektros pirkimo sutarčių vystymas.",
            "title": "Vystymas"
          },
          {
            "text": "Struktūrizavimas ir projektų finansavimo gavimas.",
            "title": "Finansavimas"
          },
          {
            "text": "Rangos ir statybos projektų valdymas, pagrįstas SIG patirtimi ir patikimų partnerių pajėgumais.",
            "title": "Statyba"
          },
          {
            "text": "Optimizacija, 24/7 stebėjimas, priežiūra, draudimas ir garantijos.",
            "title": "Aptarnavimas ir priežiūra"
          }
        ]
      }
    ],
    "title": "Verslo modelis"
  },
  "pl": {
    "progressions": [
      {
        "name": "Projekty na skalę przemysłową",
        "steps": [
          {
            "text": "Dzierżawa niezabudowanych terenów pod instalacje, uzyskiwanie niezbędnych zgód i pozwoleń oraz certyfikatu na wytwarzanie energii elektrycznej.",
            "title": "Projektowanie"
          },
          {
            "text": "Strukturyzacja i pozyskiwanie finansowania projektu.",
            "title": "Finansowanie"
          },
          {
            "text": "Zarządzanie projektami budowlanymi na podstawie wieloletniego doświadczenia SIG, współpraca z zaufanymi kontrahentami.",
            "title": "Budowa"
          },
          {
            "text": "Optymalizacja, monitoring 24/7, konserwacja, ubezpieczenie i gwarancje",
            "title": "Obsługa i konserwacja"
          }
        ]
      }
    ],
    "title": "Model biznesowy"
  }
}
</i18n>
