<template>
  <Card class="CardTestimonial">
    <div class="employee">
      <div class="pic-container">
        <Pic :src="testimonial.image.url" />
      </div>
      <Markdown class="description" :markdown="testimonial.content" />
      <p class="name-title">
        {{ testimonial.name }}, {{ testimonial.role }}
      </p>
    </div>
  </Card>
</template>

<script>
const Markdown = () => import(/* webpackChunkName: 'showdown' */ 'views/components/Markdown')

export default {
  components: { Markdown },
  props: {
    testimonial: Object,
  },
}
</script>

<style lang="scss">
.CardTestimonial {
  background: inherit;
  border: none;
  box-shadow: none;
  user-select: none;

  .employee {
    margin: 0 auto 2rem;
    max-width: 36rem;
    @include md {
      max-width: none;
    }

    .pic-container {
      max-height: 25rem;
      overflow: hidden;
      @include md {
        max-height: 18rem;
      }
      @include lg {
        max-height: 23rem;
      }

      img {
        max-width: 100%;
      }
    }

    .description {
      margin-top: 1rem;
      // padding: 0 1rem;
      text-align: justify;
      @include lg {
        min-height: 18rem;
      }
    }

    .name-title {
      // padding: 0 1rem;
      margin-top: 1rem;
      text-align: right;
    }
  }

  > .image {
    height: 4.5rem;
    padding: 2rem 2rem 0;

    img {
      height: 100%;
    }
  }

  &:hover {
    box-shadow: none;
  }

}
</style>
