<template>
  <section class="GeneralDocuments">
    <Wrap class="lg">
      <Row>
        <Column :md="6">
          <p>
            {{ $t('description') }}
            <br>
            <a class="Btn lg" :href="documentsLocations.description" target="_blank">
              {{ $t('read') }}
            </a>
          </p>
          <p>
            {{ $t('conditions') }}
            <br>
            <a class="Btn lg" :href="documentsLocations.conditions" target="_blank">
              {{ $t('read') }}
            </a>
          </p>
          <p>
            {{ $t('articles') }}
            <br>
            <a class="Btn lg" :href="documentsLocations.articles" target="_blank">
              {{ $t('read') }}
            </a>
          </p>
          <p>
            {{ $t('finalTerms') }}
            <br>
            <a class="Btn lg" :href="documentsLocations.finalTerms" target="_blank">
              {{ $t('read') }}
            </a>
          </p>
        </Column>
        <Column :md="6">
          <p>
            {{ $t('statementsConsolidated') }}
            <br>
            <a class="Btn lg" :href="documentsLocations.statementsConsolidated2022" target="_blank">
              2022
            </a>
            <br>
            <a class="Btn lg" :href="documentsLocations.statementsConsolidated2023" target="_blank">
              2023
            </a>
          </p>
          <hr>
          <p>
            {{ $t('statementsStandalone') }}
            <br>
            <a class="Btn lg" :href="documentsLocations.statementsStandalone2022" target="_blank">
              2022
            </a>
            <br>
            <a class="Btn lg" :href="documentsLocations.statementsStandalone2023" target="_blank">
              2023
            </a>
          </p>
          <hr>
          <p>
            {{ $t('statementsConsolidatedPreliminary') }}
            <br>
            <a class="Btn lg" :href="documentsLocations.statementsConsolidated" target="_blank">
              {{ $t('2024FirstHalf') }}
            </a>
          </p>
          <p>
            {{ $t('statementsStandalonePreliminary') }}
            <br>
            <a class="Btn lg" :href="documentsLocations.statementsStandalone" target="_blank">
              {{ $t('2024FirstHalf') }}
            </a>
          </p>
        </Column>
      </Row>
    </Wrap>
  </section>
</template>

<script>
import { getters } from 'views/utils'
import { webUrl } from '@/config'

export default {
  data() {
    return {
      webUrl,
      folderPath: '/assets/public-bond-issue-2024/',
      documentsFilenames: {
        en: {
          description: 'en/Information Document_Sun Investment Group_Bonds.pdf',
          conditions: 'en/TC_Sun Investment Group_Bonds.pdf',
          articles: 'en/Articles of Association.pdf',
          finalTerms: 'en/Final Terms_Sun Investment Group_Bonds.pdf',
          statementsConsolidated2022: 'en/SIG Consolidated FS 2022 EN audited.pdf',
          statementsConsolidated2023: 'en/SIG Consolidated FS 2023 EN Audited.pdf',
          statementsConsolidated: 'en/SIG Consolidated FS 2024 H1 EN Signed.pdf',
          statementsStandalone2022: 'en/SIG FS 2022 Audited EN.pdf',
          statementsStandalone2023: 'en/SIG FS 2023 Audited EN Signed.pdf',
          statementsStandalone: 'en/SIG FS 2024 H1 EN Signed.pdf',
        },
        lt: {
          description: 'en/Information Document_Sun Investment Group_Bonds.pdf',
          conditions: 'en/TC_Sun Investment Group_Bonds.pdf',
          articles: 'lt/Bendroves istatai.pdf',
          finalTerms: 'en/Final Terms_Sun Investment Group_Bonds.pdf',
          statementsConsolidated2022: 'lt/SIG Consolidated FS 2022 LT audited.pdf',
          statementsConsolidated2023: 'lt/SIG Consolidated FS 2023 LT Audited.pdf',
          statementsConsolidated: 'lt/SIG Consolidated FS 2024 H1 LT Signed.pdf',
          statementsStandalone2022: 'lt/SIG FS 2022 Audited LT Signed.pdf',
          statementsStandalone2023: 'lt/SIG FS 2023 Audited LT Signed.pdf',
          statementsStandalone: 'lt/SIG FS 2024 H1 LT Signed.pdf',
        },
      },
    }
  },
  computed: {
    documentsLocations() {
      const filenames = this.documentsFilenames[this.locale] || this.documentsFilenames.en

      return Object.fromEntries(Object.entries(filenames)
        .map(filename => [filename[0], `${this.webUrl}${this.folderPath}${filename[1]}`]))
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
.GeneralDocuments {
  p {
    font-size: $h35;
    padding-bottom: 3rem;

    .Btn {
      font-size: $h4;
      margin: 1.5rem 0;
      min-width: 15rem;
    }
  }

  hr {
    margin-bottom: 2rem;
  }
}
</style>

<i18n>
{
  "en": {
    "read": "Read",
    "2024FirstHalf": "2024 H1",
    "description": "Information document",
    "conditions": "Terms & Conditions",
    "articles": "Articles of Association",
    "finalTerms": "Final Terms",
    "statementsConsolidated": "Sets of consolidated audited financial statements",
    "statementsConsolidatedPreliminary": "Consolidated unaudited financial statements",
    "statementsStandalone": "Sets of audited standalone financial reports",
    "statementsStandalonePreliminary": "Standalone unaudited financial statements"
  },
  "lt": {
    "read": "Skaityti",
    "2024FirstHalf": "2024 Ⅰ pus.",
    "description": "Informacinis dokumentas (EN)",
    "conditions": "Sąlygos (EN)",
    "articles": "Bendrovės įstatai",
    "finalTerms": "Galutinės sąlygos (EN)",
    "statementsConsolidated": "Konsoliduotų audituotų finansinių ataskaitų rinkiniai",
    "statementsConsolidatedPreliminary": "Konsoliduotos neaudituotos finansinės ataskaitos (pelno, nuostolių bei balanso ataskaitos)",
    "statementsStandalone": "Įmonės audituotų finansinių ataskaitų rinkiniai",
    "statementsStandalonePreliminary": "Įmonės neaudituotos finansinės ataskaitos"
  }
}
</i18n>
