<template>
  <section id="SectionNews">
    <Wrap>
      <!--
      <Empty v-if="!posts.length">
        {{ $t('none') }}
      </Empty>
      -->

      <CardPost
        v-for="post in posts"
        :key="post.id"
        :post="post" />

      <PaginationShowMore
        v-if="postsCount > limitNews"
        :limit.sync="limitNews"
        name="news"
        :total="postsCount" />
    </Wrap>
  </section>
</template>

<script>
import CardPost from 'views/components/CardPost'
import PaginationShowMore from 'views/components/PaginationShowMore'
import { getters } from 'views/utils'

export default {
  components: { CardPost, PaginationShowMore },
  props: {
    tag: Object,
  },
  data() {
    return {
      limitNews: 5,
      showEnglishLocale: [
        'de',
      ],
    }
  },
  computed: {
    localeCurrent() {
      return this.showEnglishLocale.includes(this.locale) ? 'en' : this.locale
    },
    posts() {
      const queryParams = {
        locale: this.localeCurrent,
        $limit: this.limitNews,
        $sort: { date: -1 },
        ...(this.tag.doIgnore ? {} : { tag: this.tag.value }),
      }

      return this.query('post', queryParams)
    },
    postsCount() {
      return this.query('post', { locale: this.localeCurrent }).length
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
#SectionNews {
  margin-top: -5rem;
  position: relative;
  @include lg {
    margin-top: -15rem;
  }
}
</style>

<i18n>
{
  "de": {
    "none": "Keine Nachrichten gefunden"
  },
  "en": {
    "none": "No news found"
  },
  "it": {
    "none": ""
  },
  "lt": {
    "none": "Nerasta jokių naujienų"
  },
  "pl": {
    "none": "Brak nowych wiadomości"
  }
}
</i18n>
