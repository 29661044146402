<template>
  <Accordion
    ref="el"
    :id="`position-${job.id}`"
    class="OpenPosition"
    :markdown="job.content"
    :meta="location"
    :title="job.title">
    <div class="notice">
      <div class="send-to">
        {{ $t('send') }} <Email :address="job.send_to" />
      </div>
      {{ $t('notice') }}
    </div>
    <div>
      <BtnShare :to="{ name: 'Career', hash: `#position-${job.id}`, params }" />
    </div>
  </Accordion>
</template>

<script>
import Accordion from 'views/components/Accordion'
import BtnShare from 'views/components/BtnShare'
import { getters } from 'views/utils'

export default {
  components: { Accordion, BtnShare },
  props: {
    job: { type: Object, required: true },
  },
  computed: {
    params() {
      return this.locale !== 'en' ? { locale: this.locale } : {}
    },
    location() {
      return [
        this.job.company,
        this.job.city,
        this.job.country,
      ].filter(location => location).join(', ')
    },
    ...getters(),
  },
  mounted() {
    if (this.$route.hash === `#position-${this.job.id}`) this.$refs.el.open()
  },
}
</script>

<style lang="scss">
$_job-text: $text-color-light;

.Accordion.OpenPosition {
  .content {
    .notice {
      border-top: 1px solid $border-color-light;
      color: $_job-text;
      font-size: $h7;
      margin-top: 2rem;
      padding-top: 1rem;

      .send-to {
        font-size: $h5;
        margin: 1rem 0;
      }
    }
  }

  .BtnShare {
    display: block;
    margin-top: 2rem;

    .BtnRound {
      margin-bottom: unset;
      margin-right: 0.5rem;
    }

    .share-options {
      bottom: 0;
      display: inline-block;
      right: unset;
      width: unset;
    }
  }

  .BtnShare-fade {
    &-0 {
      &-enter,
      &-leave-to {
        transform: translateX(-6rem);
      }
    }

    &-1 {
      &-enter,
      &-leave-to {
        transform: translateX(-4rem);
      }
    }

    &-2 {
      &-enter,
      &-leave-to {
        transform: translateX(-2rem);
      }
    }
  }
}
</style>

<i18n>
{
  "de": {
    "notice": "Bitte fügen Sie Ihrer Bewerbung die folgende Erklärung bei: \"Hiermit erkläre ich mich damit einverstanden, dass meine personenbezogenen Daten, die ich in meinen Bewerbungsunterlagen angegeben habe, vom Anbieter zum Zweck der Durchführung des Einstellungsverfahrens gemäß dem Gesetz vom 29. August 1997 über den Schutz personenbezogener Daten (konsolidierter Text: Gesetzblatt von 2016, Pos. 922) verarbeitet werden. Ich erkläre mich außerdem damit einverstanden, dass meine personenbezogenen Daten durch den Anbieter für die Zwecke künftiger Einstellungsverfahren verarbeitet werden.\"",
    "perks": "Vergünstigungen",
    "requirements": "Anforderungen",
    "send": "Lebenslauf und Anschreiben senden an"
  },
  "en": {
    "notice": "Please include the following statement in your application: \"I hereby consent to processing by the advertiser of my personal data, included in my application documents, for the purposes to implement the recruitment process according to the act of 29 August 1997 on personal data protection (consolidated text: Journal of Laws of 2016, pos. 922). I also consent to processing of my personal data by the advertiser for the purposes of any future recruitment processes.\"",
    "perks": "Perks",
    "requirements": "Requirements",
    "send": "Send resume and cover letter to"
  },
  "it": {
    "notice": "",
    "perks": "",
    "requirements": "",
    "send": ""
  },
  "lt": {
    "notice": "Prašome į savo paraišką įtraukti šį sakinį „Aš sutinku, kad UAB „Sun investments“ tvarkytų mano asmens duomenis, įskaitant ir paraiškos dokumentus, kad užtikrintų sklandų darbuotojų atrankos ir įdarbinimo procesą, pagal 1997 rupjūčio 29 d.asmens duomenų apsaugos aktą (tekstas: Journal of Laws of 2016, pos. 922). Aš taip pat sutinku, kad įmonė tvarkytų mano asmens duomenis galimais atrankos ir įdarbinimo ateityje tikslais.” ",
    "perks": "Privalumai",
    "requirements": "Reikalavimai",
    "send": "Siųsti CV ir motyvacinį laišką"
  },
  "pl": {
    "notice": "Do wniosku dołącz następujące oświadczenie: „Wyrażam zgodę na przetwarzanie moich danych osobowych, zawartych w moich dokumentach aplikacyjnych, w celu wdrożenia procesu rekrutacji zgodnie z ustawą z dnia 29 sierpnia 1997 r. O ochronie danych osobowych ( tekst ujednolicony: Dz.U. z 2016 r., poz. 922). Wyrażam również zgodę na przetwarzanie moich danych osobowych do celów przyszłych procesów rekrutacyjnych. ”",
    "perks": "Dodatkowe korzyści",
    "requirements": "Wymagania",
    "send": "Prosimy przesłać CV i list motywacyjny na adres"
  }
}
</i18n>
