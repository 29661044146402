<template>
  <div class="Tabs">
    <ul>
      <li
        v-for="tab in tabs"
        :key="tab.name"
        :class="{ 'is-active': tab.isActive }"
        @click="selectTab(tab)"
        @keydown.enter="selectTab(tab)">
        {{ tab.name }}
      </li>
    </ul>

    <div class="Tabs-details">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [],
    }
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach((tab) => {
        tab.isActive = (tab.name === selectedTab.name)
      })
    },
  },
  created() {
    this.tabs = this.$children
  },
}
</script>

<style lang="scss">
.Tabs ul {
  list-style-type: none;
  margin-bottom: 1rem;
  text-align: center;
  @include md {
    text-align: inherit;
  }

  > li {
    @include trans;
    border-bottom: 2px solid transparent;
    color: $main;
    cursor: pointer;
    display: inline-block;
    font-weight: $semibold;
    line-height: $lh;
    padding: 0 1rem;

    &.is-active {
      border-color: inherit;
      color: $grey-dark;
    }
  }
}
</style>
