<template>
  <Route class="Card CardQA" :to="{ name: 'FaqLandowners', hash: `#Qa-${number}` }">
    <div class="body">
      <h5>{{ question }}</h5>
    </div>
  </Route>
</template>

<script>
export default {
  props: {
    question: String,
    number: Number,
  },
}
</script>

<style lang="scss">
.CardQA {
  display: block;
  min-height: 12rem;
  padding: 2rem;

  h5 {
    color: $text-color;
    display: block;
    min-height: 4.5rem;
  }
}
</style>
