<template>
  <div id="Career">
    <Hero class="career" :formats="['webp']" src="../assets/career-office.jpg">
      <Headline class="white">
        {{ $t('headline') }}
      </Headline>
      <Row>
        <Column :lg="6">
          <h2 class="career-title">
            {{ $t('title') }}
          </h2>
        </Column>
        <Column :lg="6">
          <p>{{ $t('side') }}</p>
        </Column>
      </Row>
      <HeroScrollDown hash="#SectionOpenPositions" slot="outside" />
    </Hero>
    <SectionOpenPositions />
    <SectionWhyUs />
    <SectionOurOffice />
    <SectionBlog />
    <SectionTestimonials />
    <SectionApply />
  </div>
</template>

<script>
import Hero from 'views/components/Hero'
import HeroScrollDown from 'views/components/HeroScrollDown'
import Meta from 'views/mixins/Meta'
import SectionApply from './components/SectionApply'
import SectionBlog from './components/SectionBlog'
import SectionOpenPositions from './components/SectionOpenPositions'
import SectionOurOffice from './components/SectionOurOffice'
import SectionTestimonials from './components/SectionTestimonials'
import SectionWhyUs from './components/SectionWhyUs'
import { actions } from '../../utils'

export default {
  mixins: [
    Meta(require('@/assets/social/career.jpg')),
  ],
  components: {
    Hero,
    HeroScrollDown,
    SectionApply,
    SectionBlog,
    SectionOpenPositions,
    SectionOurOffice,
    SectionTestimonials,
    SectionWhyUs,
  },
  methods: actions('FETCH_CAREER', 'FETCH_TESTIMONIAL', 'FETCH_BLOG'),
  created() {
    this.LOAD(Promise.all([
      this.FETCH_BLOG(),
      this.FETCH_CAREER(),
      this.FETCH_TESTIMONIAL(),
    ]))
  },
}
</script>

<style lang="scss">
#Career {
  .Hero-body {
    padding: 5rem 0 10rem;
  }

  .career-title {
    margin-bottom: $h5;
  }

  #SectionBlog {
    padding-bottom: 0;
  }
}
</style>

<i18n>
{
  "de": {
    "headline": "Karriere",
    "meta": {
      "description": "Wir sind stolz darauf, dass die Sun Investment Group der Pionier des Solargeschäfts in der Region ist, und wir könnten ohne ein talentiertes Team nicht dort sein, wo wir jetzt sind.",
      "title": "Karriere"
    },
    "side": "Ohne ein talentiertes Team könnten wir nicht dort sein, wo wir jetzt sind. Wir sind ehrgeizig und wir gehen vorwärts. Werden Sie Teil unseres Teams und arbeiten Sie an der Seite von Top-Solarexperten an herausfordernden Projekten und werden Sie Teil einer Lösung für den Klimawandel. Sind Sie bereit, das Spiel der Solarenergie zu verändern?",
    "title": "Werden Sie Teil des regionalen Marktführers für Solarprodukte"
  },
  "en": {
    "headline": "Career",
    "meta": {
      "description": "We're proud to say Sun Investment Group is the pioneer of solar business in the region and we couldn't be where we are now without a talented team.",
      "title": "Career"
    },
    "side": "We couldn‘t be where we are right now without a talented team. We are ambitious and we go forward. Join our team and work alongside top solar experts, tackling challenging projects and become part of a climate change solution. Ready to change the game of solar?",
    "title": "Join the regional leaders of everything solar"
  },
  "it": {
    "headline": "",
    "meta": {
      "description": "",
      "title": ""
    },
    "side": "",
    "title": ""
  },
  "lt": {
    "headline": "Karjera",
    "meta": {
      "description": "Didžiuojamės būdami saulės energetikos lyderiu regione. Už tai esame dėkingi savo komandai.",
      "title": "Karjera"
    },
    "side": "Savo komandos dėka tapome rinkos lyderiais ir neketiname sustoti. Prisijunkite prie mūsų ir kurkite ateitį kartu su pirmaujančiais saulės energetikos ekspertais. \n",
    "title": "Prisijunkite prie regionio saulės energetikos rinkos lyderių."
  },
  "pl": {
    "headline": "Kariera",
    "meta": {
      "description": "Z dumą możemy stwierdzić, że Sun Investment Group jest pionierem w branży energii solarnej w regionie. Nigdy nie osiągnęlibyśmy tej pozycji bez naszego utalentowanego zespołu.",
      "title": "Kariera"
    },
    "side": "Z dumą możemy stwierdzić, że Sun Investment Group jest pionierem w branży energii słonecznej w regionie. Nigdy nie osiągnęlibyśmy tej pozycji bez naszego utalentowanego zespołu. Nieustannie się rozwijamy, staramy się wybierać najlepszych ludzi i zapewniać im wszystko, co niezbędne, by mogli wykorzystać swój talent w pracy. Dołącz do naszego zespołu i współpracuj z najlepszymi ekspertami z obszaru energii solarnej, realizując złożone projekty z liderami z branży technologii fotowoltaicznej.",
    "title": "Dołącz do regionalnych liderów branży energii solarnej"
  }
}
</i18n>
