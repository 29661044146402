<template>
  <div class="ProgressionStep" :class="{ number: typeof icon === 'number' }">
    <div class="head">
      <div class="img">
        <Icon v-if="typeof icon === 'string'" :name="icon" />
        <div v-else class="default-icon" />
      </div>
    </div>
    <div class="body">
      <h4>
        {{ title }}
      </h4>
      <div class="text">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    title: String,
    text: String,
  },
}
</script>

<style lang="scss">
$_icon-size: 2rem;
$_icon-size-lg: 3rem;
$_icon-size-lg-quarter: 3rem / 4;

.ProgressionStep {
  margin-bottom: 6rem;
  padding: 0 1rem;
  text-align: center;
  @include md {
    margin-bottom: 0;
    padding: 0;
  }

  .head {
    color: $main;
    padding: 0 0.25rem;

    .img {
      color: inherit;
      display: inline-block;
      font-size: $_icon-size / 1.55;
      height: $_icon-size;
      line-height: $_icon-size / 1.1;
      margin-bottom: 0.75rem;
      text-align: center;
      width: $_icon-size;
      @include lg {
        font-size: $_icon-size-lg / 1.55;
        height: $_icon-size-lg;
        line-height: $_icon-size-lg * 0.9;
        margin-bottom: 1.5rem;
        width: $_icon-size-lg;
      }

      .default-icon {
        background: $main;
        border-radius: $radius-round;
        display: inline-block;
        height: $_icon-size;
        position: relative;
        width: $_icon-size;
        @include lg {
          height: $_icon-size-lg;
          position: relative;
          width: $_icon-size-lg;
        }

        &::before {
          background: mix($black, $main);
          border-radius: $radius-round;
          content: ' ';
          display: block;
          height: $_icon-size / 2;
          left: calc(50% - #{$_icon-size / 4});
          position: absolute;
          top: calc(50% - #{$_icon-size / 4});
          width: $_icon-size / 2;
          @include lg {
            height: $_icon-size-lg / 2;
            left: calc(50% - #{$_icon-size-lg / 4});
            top: calc(50% - #{$_icon-size-lg / 4});
            width: $_icon-size-lg / 2;
          }
        }
      }
    }
  }

  .body {
    > h4 {
      color: $main;
      height: 3.5rem;
      @include md {
        font-size: $h5;
      }
      @include lg {
        font-size: $h4;
        height: 2.5rem;
        margin-bottom: 1rem;
      }
    }

    .text {
      font-weight: $regular;
      line-height: $lh;
      margin: 0 auto;
      max-width: 20rem;
      @include md {
        max-width: none;
        padding: 0 0.75rem;
      }
      @include xl {
        min-height: 8rem;
        padding: 0 1rem;
      }
    }
  }

  &.number {
    .head .img {
      font-size: $_icon-size / 1.75;
      font-weight: $semibold;
      line-height: $_icon-size * 0.95;
      @include lg {
        font-size: $_icon-size-lg / 1.75;
        line-height: $_icon-size-lg * 0.95;
      }
    }
  }
}
</style>
