<template>
  <SectionSplit src="../../assets/landing-finances/1.jpg">
    <h3>
      {{ $t('title') }}
    </h3>
    <p v-for="(paragraph, index) in $t('paragraphs')" :key="index" v-html="paragraph" />
  </SectionSplit>
</template>

<script>
import SectionSplit from 'views/components/SectionSplit'

export default {
  components: { SectionSplit },
}
</script>

<style lang="scss">
</style>

<i18n>
{
  "en": {
    "title": "How to invest?",
    "paragraphs": [
      "To invest - contact the financial brokerage company/bank handling your securities account for the submission of an investment order.",
      "If you have not entered into a service contract with a financial intermediary in Lithuania, fill out the <a href=\"https://www.orion.lt/en/sig-contact-form/\" target=\"_blank\">contact form</a>, and \"Orion\" investment consultants will contact you. You can consult by e-mail <a href=\"mailto:obligacijos@orion.lt\" target=\"_blank\">obligacijos@orion.lt</a>."
    ]
  },
  "lt": {
    "title": "Kaip investuoti?",
    "paragraphs": [
      "Norėdami investuoti – kreipkitės į Jūsų vertybinių popierių sąskaitą tvarkančią finansų maklerių įmonę/banką dėl investavimo pavedimo pateikimo.",
      "Jeigu nesate sudarę paslaugų sutarties su Lietuvoje veikiančiu finansų tarpininku, užpildykite <a href=\"https://www.orion.lt/sig-kontaktine-forma/\" target=\"_blank\">kontaktų formą</a> ir su Jumis susisieks „Orion“ investicijų konsultantai. Konsultuotis galite ir elektroniniu paštu <a href=\"mailto:obligacijos@orion.lt\" target=\"_blank\">obligacijos@orion.lt</a>."
    ]
  }
}
</i18n>
