<template>
  <SectionSplit
    id="SectionOurApproach"
    class="reverse our-portfolio"
    :formats="[]"
    :headline="$t('headline')"
    src="../../assets/our-approach.jpg">
    <h3>
      {{ $t('title') }}
    </h3>
    <p>
      {{ $t('content') }}
    </p>
  </SectionSplit>
</template>

<script>
import SectionSplit from 'views/components/SectionSplit'

export default {
  components: { SectionSplit },
}
</script>

<style lang="scss">
</style>

<i18n>
{
  "de": {
    "content": "",
    "headline": "",
    "title": ""
  },
  "en": {
    "content": "In 2018 we teamed up with experienced energy and PV construction partners and successfully secured acquisition rights for solar PV projects, totalling in 42 MWp of cumulative power.",
    "headline": "Our approach",
    "title": "We saw Poland’s urgency for renewable energy as an opportunity to grow our business in the long run"
  },
  "it": {
    "content": "",
    "headline": "",
    "title": ""
  },
  "lt": {
    "content": "2018-aisiais kartu su patyrusiais energetikos ir saulės elektrinių montavimo partneriais įgijome galimybę įgyvendinti 42 MWp galios projektus. ",
    "headline": "Mūsų požiūris",
    "title": "Identifikavome, jog Lenkijos atsinaujinančios energijos sprendimų poreikis yra galimybė plėsti mūsų verslą."
  },
  "pl": {
    "content": "W 2018 r. połączyliśmy siły z doświadczonymi partnerami z branży energetycznej i fotowoltaicznej, dzięki czemu udało nam się zdobyć prawa do zakupu projektów fotowoltaicznych o łącznej mocy 42 MWp.",
    "headline": "Nasze podejście",
    "title": "Dostrzegliśmy w Polsce zapotrzebowanie na energię odnawialną i uznaliśmy to za szansę na rozwój naszej firmy w dłuższej perspektywie."
  }
}
</i18n>
