<template>
  <div class="TeamMember">
    <Row class="no-wrap">
      <Column xs="auto">
        <TeamMemberImage :member="member" />
      </Column>
      <Column class="body" :grow="1" xs="auto">
        <Wrap>
          <h3>
            {{ member.name }}
          </h3>
          <div class="role">
            {{ member.role }}
          </div>
          <p>
            {{ member.description }}
          </p>
        </Wrap>
      </Column>
    </Row>
  </div>
</template>

<script>
import TeamMemberImage from './TeamMemberImage'

export default {
  components: { TeamMemberImage },
  props: {
    member: Object,
  },
}
</script>

<style lang="scss">
.TeamMember {
  background: $white;
  margin: auto;
  margin-bottom: 4rem;

  .Row {
    flex-direction: column;
    @include md {
      flex-direction: row;
    }

    .body {
      align-items: center;
      display: flex;
      flex-direction: initial;
      padding: 2rem 1rem;

      > .Wrap {
        max-width: 40rem;
      }

      .role {
        margin: 0.75rem 0 3rem;
      }

      p {
        opacity: 0.7;
      }
    }
  }
}
</style>

<i18n>
{
  "de": {
    "linkedin": "LinkedIn Profil"
  },
  "en": {
    "linkedin": "LinkedIn profile"
  },
  "it": {
    "linkedin": ""
  },
  "lt": {
    "linkedin": "Linkedin"
  },
  "pl": {
    "linkedin": "Linkedin"
  }
}
</i18n>
