<template>
  <SectionSplit class="OtherDocuments reverse" src="../../assets/landing-finances/3.jpg">
    <h3>
      {{ $t('title') }}
    </h3>

    <p
      v-for="(link, index) in $t('links')"
      :key="index"
      class="link"
      v-html="injectCustomSnippets(link, documentsLocations)" />

    <p> {{ $t('text') }} </p>
  </SectionSplit>
</template>

<script>
import SectionSplit from 'views/components/SectionSplit'
import { getters } from 'views/utils'
import { webUrl } from '@/config'

export default {
  components: { SectionSplit },
  data() {
    return {
      webUrl,
      folderPath: '/assets/public-bond-issue/other/',
      documentsFilenames: {
        // summaryEN: 'summaryEN.pdf',
        // fullDescriptionEN: 'fullDescriptionEN.pdf',
        termsENG: 'en/Sun-Investment-Group-BondsTerms-and-Conditions.pdf',
        informationDocumentEN: 'en/Sun-Investment-Group_Bonds_Information-Document.pdf',
        // summaryLT: 'summaryLT.pdf',
        // fullDescriptionLT: 'fullDescriptionLT.pdf',
        // informationDocumentLT: 'en/Sun-Investment-Group_Bonds_Information-Document.pdf',
      },
    }
  },
  computed: {
    documentsLocations() {
      return Object.fromEntries(Object.entries(this.documentsFilenames)
        .map(filename => [filename[0], `${this.webUrl}${this.folderPath}${filename[1]}`]))
    },
    ...getters('injectCustomSnippets'),
  },
}
</script>

<style lang="scss">
.OtherDocuments {
  .link {
    font-weight: $bold;
  }
}
</style>

<i18n>
{
  "en": {
    "title": "Other related documents",
    "links": [
    "<a href=\"{termsENG}\" target=\"_blank\">Terms & Conditions</a>",
      "<a href=\"{informationDocumentEN}\" target=\"_blank\">Information document</a>"
    ],
    "text": "Orion Securities recommends that before making an investment decision, you consult with your financial advisor and evaluate all the risks associated with the financial instrument and/or other circumstances that are significant to you."
  },
  "lt": {
    "title": "Kiti susiję dokumentai",
    "links": [
      "<a href=\"{termsENG}\" target=\"_blank\">Sąlygos (ENG)</a>",
      "<a href=\"{informationDocumentEN}\" target=\"_blank\">Informacinis dokumentas (ENG)</a>"
    ],
    "text": "„Orion Securities“ rekomenduoja prieš priimant investicinį sprendimą pasitarti su Jūsų finansų konsultantu bei įvertinti visas su finansine priemone susijusias rizikas ir/ar kitas Jums reikšmingas aplinkybes."
  }
}
</i18n>
