<template>
  <section id="SectionTestimonials">
    <Wrap>
      <Headline>{{ $t('headline') }}</Headline>
      <h2>{{ $t('title') }}</h2>
      <WrapScroll :isScrollBarTop="true">
        <Column v-for="testimonial in testimonials" :key="testimonial.id" :md="4">
          <CardTestimonial :testimonial="testimonial" />
          <!-- <div class="employee">
            <div class="pic-container">
              <Pic :src="testimonial.image.url" />
            </div>
            <Markdown class="description" :markdown="testimonial.content" />
            <p class="name-title">
              {{ testimonial.name }}, {{ testimonial.role }}
            </p>
          </div> -->
        </Column>
      </WrapScroll>
    </Wrap>
  </section>
</template>

<script>
import CardTestimonial from 'views/components/CardTestimonial'
import WrapScroll from 'views/components/WrapScroll'
import { getters } from '../../../utils'

// const Markdown = () => import(/* webpackChunkName: 'showdown' */ 'views/components/Markdown')

export default {
  components: { WrapScroll, CardTestimonial },
  computed: {
    testimonials() {
      // TODO: make normal sorting
      const testimonialsAll = this.query('testimonial', { locale: this.locale })
      const firstTestimonial = testimonialsAll.find(testimonial => testimonial.name === 'Angelantonio Pugliese')
      const firstTestimonialId = firstTestimonial?.id || 0
      return [
        ...firstTestimonial ? [firstTestimonial] : [],
        ...testimonialsAll.filter(testimonial => testimonial.id !== firstTestimonialId),
      ]
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
#SectionTestimonials {
  h2 {
    margin-bottom: 3.5rem;
    text-align: center;
    @include md {
      text-align: left;
    }
  }

  .Row {
    // .employee {
    //   margin: 0 auto 6rem;
    //   max-width: 36rem;
    //   @include md {
    //     max-width: none;
    //   }

    //   .pic-container {
    //     overflow: hidden;
    //     @include md {
    //       max-height: 18rem;
    //     }
    //     @include lg {
    //       max-height: 23rem;
    //     }

    //     img {
    //       max-width: 100%;
    //     }
    //   }

    //   .description {
    //     margin-top: 1rem;
    //     text-align: justify;
    //     @include lg {
    //       min-height: 18rem;
    //     }
    //   }

    //   .name-title {
    //     margin-top: 1rem;
    //     text-align: right;
    //   }
    // }
  }
}
</style>

<i18n>
{
  "de": {
    "headline": " Was unsere Mitarbeiter zu sagen haben",
    "title": "Wir sind #SIG"
  },
  "en": {
    "headline": "What our people have to say",
    "title": "We are #SIG"
  },
  "it": {
    "headline": "",
    "title": ""
  },
  "lt": {
    "headline": "Ką sako mūsų komanda?",
    "title": "Mes esame #SIG"
  },
  "pl": {
    "headline": "Co mówi nasz team?",
    "title": "Opinie członków zespołu"
  }
}
</i18n>
