<template>
  <div id="NewsPress">
    <Hero>
      <h1>
        {{ $t('title') }}
      </h1>
      <!-- <div v-if="hasMultipleFilters" class="filters">
        <Filters
          :activeIndex.sync="activeTagIndex"
          :options="tags" />
      </div> -->
    </Hero>

    <SectionNews :tag="activeTag" />

    <SectionPress />
  </div>
</template>

<script>
import { actions, getters, mergeLabels } from '../../utils'
import Hero from 'views/components/Hero'
import Meta from 'views/mixins/Meta'
import SectionNews from './components/SectionNews'
import SectionPress from './components/SectionPress'

export default {
  mixins: [
    Meta(require('@/assets/social/news.jpg')),
  ],
  components: { Hero, SectionNews, SectionPress },
  data() {
    return {
      selectionPress: 10,
      activeTagIndex: 0,
      tagsValues: [
        { doIgnore: true },
        { value: 'Industry news' },
        { value: 'SIG news' },
      ],
    }
  },
  computed: {
    activeTag() {
      return this.tags[this.activeTagIndex]
    },
    // hasMultipleFilters() {
    //   const localePosts = this.query('post', { locale: this.locale })
    //   return Object.values(groupByKey(localePosts, 'tag')).length > 1
    // },
    tags() {
      return mergeLabels(this.tagsValues, this.$ta('tags'))
    },
    ...getters(),
  },
  methods: actions('FETCH_PRESS', 'FETCH_POST'),
  created() {
    this.LOAD(Promise.all([
      this.FETCH_PRESS(),
      this.FETCH_POST(),
    ]))
  },
}
</script>

<style lang="scss">
#NewsPress {
  .Hero {
    background-image: url('../../../assets/ellipses.png');
    background-position: right;
    background-size: cover;
    text-align: center;

    h1 {
      margin-bottom: 4rem;
    }

    .filters {
      > .Btn {
        margin-right: 1rem;

        &.active {
          background: $black-bis;
          border-color: $black-bis;
        }
      }
    }
  }

  #media {
    padding-top: 0;
  }
}
</style>

<i18n>
{
  "de": {
    "meta": {
      "title": "News & Presse"
    },
    "tags": [
      "Alle",
      "Branchennachrichten",
      "SIG Nachrichten"
    ],
    "title": "Nachrichten"
  },
  "en": {
    "meta": {
      "title": "News & Press"
    },
    "tags": [
      "All",
      "Industry news",
      "SIG news"
    ],
    "title": "News"
  },
  "it": {
    "meta": {
      "title": ""
    },
    "tags": [
      "",
      "",
      ""
    ],
    "title": ""
  },
  "lt": {
    "meta": {
      "title": "Naujienos ir spauda"
    },
    "tags": [
      "Visi",
      "Sektoriaus naujienos",
      "SIG naujienos"
    ],
    "title": "Paskutinės naujienos"
  },
  "pl": {
    "meta": {
      "title": "Komunikaty prasowe"
    },
    "tags": [
      "Wszystko",
      "Newsy branżowe",
      "Wiadomości dotyczące SIG"
    ],
    "title": "Najświeższe informacje"
  }
}
</i18n>
