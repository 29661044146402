<template>
  <section class="InvestmentDetails">
    <Wrap class="lg">
      <h3>
        {{ $t('title') }}
      </h3>

      <div class="details-wrapper">
        <table class="details">
          <tr v-for="(detail, index) in $t('details')" :key="index">
            <th>{{ detail[0] }}</th>
            <td>{{ detail[1] }}</td>
          </tr>
        </table>
      </div>
    </Wrap>
  </section>
</template>

<script>
export default {
  components: {},
}
</script>

<style lang="scss">
.InvestmentDetails {
  .details-wrapper {
    display: flex;
    justify-content: center;
    padding: 3rem 0;

    table.details {
      min-width: 50vw;

      th, td {
        border: 2px solid $main;
        padding: 1.5rem;
        text-align: center;
        @include lg {
          font-size: $h5;
        }
      }

      th {
        width: 65%;
        @include lg {
          width: 75%;
        }
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "title": "Investment details",
    "details": [
      [
        "Tranche size",
        "8 M Eur"
      ],
      [
        "Maturity",
        "2 years"
      ],
      [
        "Annual interest rate",
        "11.5%"
      ],
      [
        "Coupon payment rate",
        "Semi-annual"
      ],
      [
        "Minimum investment amount",
        "1000 Eur"
      ],
      [
        "Distribution date",
        "4th – 22nd November, 2024"
      ]
    ]
  },
  "lt": {
    "title": "Investicijos detalės",
    "details": [
      [
        "Platinamos obligacijų emisijos dydis",
        "8 mln. Eur"
      ],
      [
        "Emisijos trukmė",
        "2 metai"
      ],
      [
        "Metinės palūkanos",
        "11.5%"
      ],
      [
        "Kuponų mokėjimai",
        "Pusmetiniai"
      ],
      [
        "Minimali investicijos suma",
        "1000 eurų"
      ],
      [
        "Platinimo data",
        "Lapkričio 4-22 d., 2024"
      ]
    ]
  }
}
</i18n>
