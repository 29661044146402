<template>
  <section id="SectionWhyUs">
    <Wrap>
      <Splitter :left="8" :right="4">
        <div slot="left" class="why-us">
          <Headline class="white">
            {{ $t('headline') }}
          </Headline>
          <h2>{{ $t('title') }}</h2>
          <p v-for="(paragraph, index) in $ta('paragraphs')" :key="index">
            {{ paragraph }}
          </p>
        </div>

        <div slot="right" class="stats">
          <Row v-for="(statsSubRows, rowIndex) in statRows" :key="rowIndex">
            <Column
              v-for="stat in statsSubRows"
              :key="stat.name"
              :lg="6"
              :md="3"
              :xs="6">
              <StatNumber
                :name="stat.label"
                :number="stat.number"
                :prefix="stat.prefix"
                :suffix="stat.suffix" />
            </Column>
          </Row>
        </div>
      </Splitter>
    </Wrap>
  </section>
</template>

<script>
import { getters, mergeLabels } from 'views/utils'
import Splitter from 'views/components/Splitter'
import StatNumber from 'views/components/StatNumber'

export default {
  components: { Splitter, StatNumber },
  computed: {
    stats() {
      return mergeLabels(this.statsValues, this.$ta('stats'))
    },
    statRows() {
      return this.splitIntoRows(this.stats, {
        lg: 2,
        md: 4,
        xs: 2,
      })
    },
    statsValues() {
      return [
        { number: this.getSnippetValue('experts') || 100, suffix: '+' },
        { number: Number(this.getSnippetValue('countries') || 100) },
        { number: this.getSnippetValue('constructedMw') || 100, suffix: 'MW' },
      ]
    },
    ...getters('getSnippetValue', 'splitIntoRows'),
  },
}
</script>

<style lang="scss">
#SectionWhyUs {
  background-color: $blue;
  color: $white;

  h2 {
    margin-bottom: $h2;
  }

  .Splitter {
    flex-direction: column;
    @include lg {
      flex-direction: row;
    }

    .Splitter-body {
      @include md {
        max-width: unset;
      }
    }

    .why-us {
      @include lg {
        padding-right: 2rem;
      }

      p {
        margin-bottom: 1rem;
      }
    }

    .stats {
      margin-top: 4rem;
      @include lg {
        margin-top: 0;
      }

      .StatNumber {
        height: 8rem;
        padding: 1rem 0;
        text-align: center;
        @include lg {
          // height: 130px;
        }

        .number,
        .name {
          color: $white;
          opacity: 1;
          @include lg {
            white-space: nowrap;
          }
        }

        .number {
          font-size: $h2;
        }
      }

      .Row:not(:last-child) {
        border-bottom: 1px solid $white;
      }

      .Row:last-child {
        .Column {
          border-left: unset;
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      .Column {
        min-width: 10rem;
        @include md {
          &:not(:first-child) {
            border-left: 1px solid $white;
          }
        }

        &:last-child {
          border-left: 1px solid $white;
        }
      }
    }
  }
}
</style>

<i18n>
{
  "de": {
    "headline": "Warum wir?",
    "paragraphs": [
      "Wir existieren, um die Welt positiv zu verändern, indem wir die Solarenergie auf die Landkarte bringen. Wir schaffen Veränderungen, indem wir ein inspirierendes und nachhaltiges Unternehmen aufbauen, das in seine Mitarbeiter und die Menschen, mit denen es arbeitet, investiert. In einer Umgebung, die von Vertrauen und Loyalität geprägt ist, unterstützen wir unsere Talente bei jedem Schritt des Weges, indem wir Weltklasse-Führungskräfte für eine immer hellere Zukunft heranziehen.",
      "Wir setzen alles daran, hoch motivierte Mitarbeiter zu gewinnen und zu fördern, die sich gerne herausfordern lassen und unsere Grundwerte teilen.",
      "Klingt gut? Wir können es kaum erwarten, Sie kennenzulernen!",
      ""
    ],
    "stats": [
      {
        "label": "Teammitglieder"
      },
      {
        "label": "Länder"
      },
      {
        "label": "gebaut"
      }
    ],
    "title": "Solarenergie im Wandel"
  },
  "en": {
    "headline": "Why us?",
    "paragraphs": [
      "We exist to make positive changes in the world by putting solar energy on the map. We deliver change by building an inspiring and sustainable business that invests in its people and those it works with. In an environment of trust and loyalty , we support our talent in every step of the way cultivating world-class leaders for an ever-brighter future.",
      "We go great lengths to attract and nurture highly motivated people who love to be challenged and share our core values.",
      "Sounds good? We can’t wait to meet you!",
      ""
    ],
    "stats": [
      {
        "label": "Team members"
      },
      {
        "label": "Countries"
      },
      {
        "label": "Constructed"
      }
    ],
    "title": "Changing the game of solar"
  },
  "it": {
    "headline": "",
    "paragraphs": [
      "",
      "",
      "",
      ""
    ],
    "stats": [
      {
        "label": ""
      },
      {
        "label": ""
      },
      {
        "label": ""
      }
    ],
    "title": ""
  },
  "lt": {
    "headline": "Kodėl SIG?",
    "paragraphs": [
      "Dirbame tam, kad pasitelkę saulės energijos sprendimus keistume pasaulį į gera. Įgaliname pokyčius, augindami tvarų ir įkvepiantį verslą bei investuodami į darbuotojus ir partnerius. Kurdami pasitikėjimo ir palaikymo kupina aplinką, siekiame, kad SIG komanda taptų pasauliniais lyderiais ir užtikrintų šviesesnę ateitį. ",
      "Dedame daug pastangų, kad sudomintume ir puoselėtume motyvuotus, iššūkių trokštančius profesionalus, kuriems artimos SIG vertybės.",
      "Skamba patraukliai? Nekantraujame susipažinti!",
      ""
    ],
    "stats": [
      {
        "label": "Komanda"
      },
      {
        "label": "Šalys"
      },
      {
        "label": "Pastatyta"
      }
    ],
    "title": "Čia kuriamos saulės energetikos tendencijos"
  },
  "pl": {
    "headline": "Dlaczego my?",
    "paragraphs": [
      "W SIG koncentrujemy się na inwestowaniu w mądrzejszą przyszłość. Dla nas oznacza to przede wszystkim dostrzeganie możliwości, a także elastyczność i innowacyjność. Nasze podejście jest jasne i transparentne: wykorzystujemy specjalistyczną wiedzę, aby uzyskać najlepsze rezultaty.",
      "Dokładamy wszelkich starań, aby przyciągnąć do spółki wysoce zmotywowanych ludzi, których pociągają wyzwania, i którym bliskie są nasze wartości: stawianie czoła przeciwnościom, oddanie i uczciwość.",
      "Tworzymy środowisko oparte na zaufaniu i lojalności, w którym wspieramy swoich utalentowanych pracowników na każdym kroku, motywując ich do rozwoju. Chcemy, by stali się liderami światowej klasy, działającymi na rzecz lepszej przyszłości.",
      ""
    ],
    "stats": [
      {
        "label": "Zespół"
      },
      {
        "label": "Państwa"
      },
      {
        "label": "Zbudowana"
      }
    ],
    "title": "Changing the game of solar"
  }
}
</i18n>
