<template>
  <section id="SectionPhilosophy">
    <Wrap class="philosophy-wrap">
      <Row>
        <Column class="headlines" :md="4" :xl="3">
          <div
            v-for="(headline, index) in philosophyHeadlines"
            :key="headline"
            class="headline"
            :class="{ 'active': selectedIndex === index }"
            @click="selectedIndex = index"
            @keypress.enter="selectedIndex = index">
            {{ headline }}
          </div>
        </Column>

        <Column class="body" :md="8">
          <Headline>{{ $t('headline') }}</headline>
          <h2>{{ philosophyCurrent.headline }}</h2>
          <div>
            <Markdown class="content" :markdown="philosophyCurrent.content" />
          </div>
        </Column>
      </Row>

      <Row class="dots">
        <div
          v-for="(headline, index) in philosophyHeadlines"
          :key="headline"
          class="dot"
          :class="{ 'active': selectedIndex === index }"
          :md="1"

          @click="selectedIndex = index"
          @keypress.enter="selectedIndex = index">
          .
        </div>
      </Row>
    </Wrap>
  </section>
</template>

<script>
const Markdown = () => import(/* webpackChunkName: 'showdown' */ 'views/components/Markdown')

export default {
  components: { Markdown },
  data() {
    return {
      selectedIndex: 0,
    }
  },
  computed: {
    philosophies() {
      return this.$ta('philosophies')
    },
    philosophyHeadlines() {
      return this.philosophies.map(item => item.headline)
    },
    philosophyCurrent() {
      return this.philosophies[this.selectedIndex]
    },
  },
}
</script>

<style lang="scss">
#SectionPhilosophy {
  background: $black;
  color: $white;
  display: flex;
  min-height: 100vh;
  padding: 6rem 1rem;
  @include md {
    padding: 8rem 1rem;
  }

  .philosophy-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .Btn {
    margin-top: 2rem;
    padding: 1rem 2rem;
  }

  .dots {
    display: flex;
    font-size: 5rem;
    justify-content: center;
    padding-top: 3rem;

    .dot {
      cursor: pointer;
      font-weight: $bold;
      margin-bottom: 1.5rem;
      margin-left: 3rem;
      margin-top: 3rem;
      opacity: 0.6;
      position: relative;

      &:first-child {
        margin-left: 0;
      }

      &.active {
        color: $main;
        opacity: 1;

        &::before {
          color: $white;
          // content: '-';
          //left: -2rem;
          position: absolute;
        }
      }
    }
  }

  .headlines {
    display: flex;
    justify-content: space-around;
    @include md {
      flex-direction: column;
      justify-content: flex-start;
    }

    .headline {
      cursor: pointer;
      font-weight: $semibold;
      margin-bottom: 1.5rem;
      opacity: 0.6;
      position: relative;
      text-transform: uppercase;
      @include md {
        letter-spacing: 1px;
        margin-bottom: 3rem;
      }

      &.active {
        opacity: 1;

        &::before {
          color: $white;
          content: '-';
          left: -2rem;
          position: absolute;
        }
      }
    }
  }

  .body {
    margin-top: 10%;
    min-height: 30rem;
    @include md {
      margin-top: 0;
    }
  }

  .Headline {
    margin-top: 2rem;
  }

  > .Wrap {
    h2 {
      margin: 3rem 0;
    }

    .content {
      opacity: 0.6;

      ul {
        margin-left: 1rem;
        margin-top: 1.5rem;
      }
    }
  }
}
</style>

<i18n>
{
  "de": {
    "headline": "Unsere Philosophie",
    "philosophies": [
      {
        "content": "Die Chance - Wir konzentrieren uns auf Anlagen in Regionen, deren politische Stabilität, rechtlicher Rahmen und bestehende Energie- und Umweltpolitik langfristig eine stabile Einnahmequelle garantieren",
        "headline": "Die Chance"
      },
      {
        "content": "Wir stützen unsere Arbeit auf zentrale operative Prinzipien:\n- Wir konzentrieren uns ausschließlich auf PV-Technologie\n- Wir arbeiten in stabilen EU-Ländern mit hohem Wachstumspotenzial für die PV-Industrie\n- Wir sichern optimale Betriebsablaeufe unserer pv Anlagen durch aktives Management unserer Servicepartner und eigene Teams",
        "headline": "Der Ansatz"
      },
      {
        "content": "Als Unternehmen für erneuerbare Energien sind wir Teil der Lösung für den Klimawandel. Wir arbeiten auch daran, unsere eigenen Kohlendioxidemissionen jedes Jahr um einen bedeutenden Betrag zu reduzieren, indem wir bei unseren Mitarbeitern einen Geist der kollektiven Verantwortung wecken",
        "headline": "Der Gewinn"
      }
    ]
  },
  "en": {
    "headline": "Our philosophy",
    "philosophies": [
      {
        "content": "Our focus is on plants in regions whose political stability, legal framework, and existing energy and environmental policies guarantee a stable source of revenue in the long term.",
        "headline": "The opportunity"
      },
      {
        "content": "We base our work on core operating principles:\n- Focus exclusively on PV technology\n- Operate in stable EU countries with a high-growth potential for PV\n- Assemble and retain the best operational teams on the market in order to maintain\n- Leadership and deliver the maximum value and security to investors",
        "headline": "The approach"
      },
      {
        "content": "As a renewable energy company, we are part of the climate change solution. We’re also working to reduce our own carbon emissions by a meaningful amount each year by engendering a spirit of collective responsibility amongst our employees.",
        "headline": "The gain"
      }
    ]
  },
  "it": {
    "headline": "",
    "philosophies": [
      {
        "content": "",
        "headline": ""
      },
      {
        "content": "",
        "headline": ""
      },
      {
        "content": "",
        "headline": ""
      }
    ]
  },
  "lt": {
    "headline": "Mūsų filosofija",
    "philosophies": [
      {
        "content": "Savo projektams renkamės regionus, kurių politinis stabilumas, teisinė aplinka bei energiją ir aplinkos apsaugą apibrėžiantys įstatymai užtikrina stabilų ir ilgalaikį projektų atsiperkamumą.",
        "headline": "Galimybė"
      },
      {
        "content": "Savo darbą grindžiame pagrindiniais principais:- Specializuojamės tik saulės energetikos srityje - Dirbame stabiliose ES rinkose, turinčiose aukštą saulės energetikos plėtros potencialą - Buriame geriausių savo srities specialistų komandas -Siekiame lyderiauti ir užtikrinti investuotojams maksimalią naudą bei saugumą",
        "headline": "Požiūris"
      },
      {
        "content": "Kaip atsinaujinančios energetikos įmonė, savo darbe mes kuriame sprendimus klimato kaitos problemai. Darbuotojams diegdami kolektyvinės atsakomybės vertybes, stengiamės kasmet reikšmingai sumažinti mūsų išmetamųjų dujų emisijų kiekį. ",
        "headline": "Nauda"
      }
    ]
  },
  "pl": {
    "headline": "Nasza filozofia",
    "philosophies": [
      {
        "content": "Koncentrujemy się na budowie elektrowni w regionach, gdzie stabilność polityczna, ramy prawne oraz strategie polityczne w obszarze energetyki i ochrony środowiska gwarantują stabilne źródło dochodów w perspektywie długoterminowej.",
        "headline": "Możliwości"
      },
      {
        "content": "Nasza praca opiera się na nastepujących zasadach:\n- koncentrujemy się wyłącznie na technologiach fotowoltaicznych,\n- działamy w stabilnych krajach UE o wysokim potencjale rozwoju branży fotowoltaicznej,\n- budujemy najlepsze, trwałe zespoły operacyjne, aby utrzymać pozycję lidera na rynku i zapewnić inwestorom maksymalne zyski i bezpieczeństwo.",
        "headline": "Podejście"
      },
      {
        "content": "Jako firma zajmująca się energią odnawialną uczestniczymy w tworzeniu rozwiązań służących walce ze zmianami klimatu. Nieustannie podejmujemy wysiłki na rzecz redukcji emisji dwutlenku węgla, budując ducha zbiorowej odpowiedzialności wśród naszych pracowników.",
        "headline": "Misja"
      }
    ]
  }
}
</i18n>
